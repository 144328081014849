import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { getLang, getClang } from '@client/common/utils/fn';
import { UPDATE_COMMON_LANGPKG, UPDATE_LANG, GET_CLANG_LIST, UPDATE_CLANG, UPDATE_USER_INFO, GET_DECORATION_INFO_LIST } from './actions';
var merge = require('deepmerge');
var initialState = {
  langPkg: {},
  lang: parseInt(getLang(), 10),
  clangList: [],
  clang: parseInt(getClang() || '1000', 10),
  userInfo: {},
  decorations: [] // 徽章列表
};
function commonReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var type = action.type,
    payload = action.payload;
  switch (type) {
    case UPDATE_COMMON_LANGPKG:
      return _objectSpread(_objectSpread({}, state), {}, {
        langPkg: merge(state.langPkg, payload)
      });
    case UPDATE_LANG:
      return _objectSpread(_objectSpread({}, state), {}, {
        lang: payload
      });
    case GET_CLANG_LIST:
      return _objectSpread(_objectSpread({}, state), {}, {
        clangList: payload
      });
    case UPDATE_CLANG:
      return _objectSpread(_objectSpread({}, state), {}, {
        clang: payload,
        userInfo: _objectSpread(_objectSpread({}, state.userInfo), {}, {
          clang: payload
        })
      });
    case UPDATE_USER_INFO:
      return _objectSpread(_objectSpread({}, state), {}, {
        userInfo: payload,
        lang: payload.lang ? parseInt(payload.lang, 10) : state.lang,
        clang: payload.clang ? parseInt(payload.clang, 10) : state.clang
      });
    case GET_DECORATION_INFO_LIST:
      return _objectSpread(_objectSpread({}, state), {}, {
        decorations: payload
      });
    default:
      return state;
  }
}
export default commonReducer;