var channel;

/**
 * @returns {BroadcastChannel | undefined}
 */
export var getAuthChannel = function getAuthChannel() {
  if (!channel && typeof window.BroadcastChannel === 'function') {
    channel = new BroadcastChannel('nimo-auth');
  }
  return channel;
};
export var emitAuthUpdate = function emitAuthUpdate() {
  try {
    var bc = getAuthChannel();
    if (bc) {
      bc.postMessage({
        type: 'update'
      });
    }
  } catch (e) {
    // ignore
  }
};
export var onAuthChannelMessage = function onAuthChannelMessage(listener) {
  var bc = getAuthChannel();
  if (bc) {
    bc.addEventListener('message', listener);
    return function () {
      bc.removeEventListener('message', listener);
    };
  }
  return function () {};
};