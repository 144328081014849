export var sphdExp = '919.1197';
export default [[{
  params: {
    groupId: '831',
    g_trafficAlloc: ['0~9999'],
    salt: '9015575a',
    v_trafficAlloc: ['0~4999']
  },
  oexp: '919.1196'
}, {
  params: {
    groupId: '831',
    g_trafficAlloc: ['0~9999'],
    salt: '9015575a',
    v_trafficAlloc: ['5000~9999']
  },
  oexp: sphdExp
}]];