export var FullscreenApi = {
  requestFullscreen: '',
  exitFullscreen: '',
  fullscreenElement: '',
  fullscreenEnabled: '',
  fullscreenchange: '',
  fullscreenerror: ''
};
var checked = false;

/**
 * 用于检测浏览器支持的全屏API方法和事件名
 */
export function checkBrowserAPISupport() {
  if (checked) {
    return;
  }
  checked = true;
  // browser API methods
  var apiMap = [['requestFullscreen', 'exitFullscreen', 'fullscreenElement', 'fullscreenEnabled', 'fullscreenchange', 'fullscreenerror'],
  // WebKit
  ['webkitRequestFullscreen', 'webkitExitFullscreen', 'webkitFullscreenElement', 'webkitFullscreenEnabled', 'webkitfullscreenchange', 'webkitfullscreenerror'],
  // Old WebKit (Safari 5.1)
  ['webkitRequestFullScreen', 'webkitCancelFullScreen', 'webkitCurrentFullScreenElement', 'webkitCancelFullScreen', 'webkitfullscreenchange', 'webkitfullscreenerror'],
  // Mozilla
  ['mozRequestFullScreen', 'mozCancelFullScreen', 'mozFullScreenElement', 'mozFullScreenEnabled', 'mozfullscreenchange', 'mozfullscreenerror'],
  // Microsoft
  ['msRequestFullscreen', 'msExitFullscreen', 'msFullscreenElement', 'msFullscreenEnabled', 'MSFullscreenChange', 'MSFullscreenError']];
  var specApi = apiMap[0];
  var browserApi = [];
  // 统一用该方法，而不用videojs提供的方法
  // 若已存在 fullscreen方法，则不修改
  // if (parent.fullscreen && typeof parent.fullscreen === 'function') {
  //   return;
  // }

  // determine the supported set of functions
  for (var i = 0; i < apiMap.length; i += 1) {
    // check for exitFullscreen function
    if (apiMap[i][1] in document) {
      browserApi = apiMap[i];
      break;
    }
  }

  // map the browser API names to the spec API names
  if (browserApi) {
    for (var _i = 0; _i < browserApi.length; _i += 1) {
      FullscreenApi[specApi[_i]] = browserApi[_i];
    }
  }
}
export var isFullscreen = function isFullscreen() {
  checkBrowserAPISupport();
  return Boolean(document[FullscreenApi.fullscreenElement]);
};
export var exitFullscreen = function exitFullscreen() {
  checkBrowserAPISupport();
  if (isFullscreen() && document[FullscreenApi.exitFullscreen]) {
    document[FullscreenApi.exitFullscreen]();
  }
};