module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="page-user-video-skeleton-container">\n  ';
 for (var j = 0; j < 5; j++ ) { ;
__p += '\n  <div style="padding: 10px 16px;">\n    <div style="padding-top: 56.25%;" class="n-sk"></div>\n    <div class="bc7 n-as-padt-xs n-as-padh-xs">\n      <div class="n-sk n-sk-t12 n-as-mrgb-xs"></div>\n      <div class="n-fx-bc n-as-padb-xs">\n        <div class="n-fx-sc">\n          <div class="n-sk n-sk-ic14"></div>\n          <div class="n-sk n-sk-t12 n-as-w20px"></div>\n        </div>\n        <div class="n-sk n-sk-t12 n-as-w20px"></div>\n      </div>\n    </div>\n  </div>\n  ';
 } ;
__p += '\n</div>\n';

}
return __p
}