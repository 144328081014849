// 获取父页面URL
export var getParentUrl = function getParentUrl() {
  var url = '';
  if (window.parent !== window) {
    try {
      url = parent.location.href;
    } catch (e) {
      url = document.referrer;
    }
  }
  return url;
};

// 获取父页面host
export var getParentHost = function getParentHost() {
  var url = getParentUrl();
  return new URL(url).host;
};