import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var EClientType = {
  ClientTypeIos: 100,
  ClientTypeAndroid: 200,
  ClientTypeWinPhone: 300,
  ClientTypePc: 400,
  ClientTypeWeb: 500
};
Nimo.EClientType = EClientType;
export default EClientType;