var _typeof = require("@babel/runtime/helpers/typeof");
require("core-js/modules/es6.reflect.construct.js");
require("core-js/modules/es6.object.set-prototype-of.js");
require("core-js/modules/es6.object.get-prototype-of.js");
require("core-js/modules/es6.typed.float64-array.js");
require("core-js/modules/es6.typed.int32-array.js");
require("core-js/modules/es6.math.clz32.js");
require("core-js/modules/es6.math.imul.js");
!function (t, r) {
  "object" == (typeof exports === "undefined" ? "undefined" : _typeof(exports)) && "object" == (typeof module === "undefined" ? "undefined" : _typeof(module)) ? module.exports = r() : "function" == typeof define && define.amd ? define([], r) : "object" == (typeof exports === "undefined" ? "undefined" : _typeof(exports)) ? exports.abtest = r() : t.abtest = r();
}(window, function () {
  return function (t) {
    var r = {};
    function e(i) {
      if (r[i]) return r[i].exports;
      var n = r[i] = {
        i: i,
        l: !1,
        exports: {}
      };
      return t[i].call(n.exports, n, n.exports, e), n.l = !0, n.exports;
    }
    return e.m = t, e.c = r, e.d = function (t, r, i) {
      e.o(t, r) || Object.defineProperty(t, r, {
        enumerable: !0,
        get: i
      });
    }, e.r = function (t) {
      "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(t, Symbol.toStringTag, {
        value: "Module"
      }), Object.defineProperty(t, "__esModule", {
        value: !0
      });
    }, e.t = function (t, r) {
      if (1 & r && (t = e(t)), 8 & r) return t;
      if (4 & r && "object" == _typeof(t) && t && t.__esModule) return t;
      var i = Object.create(null);
      if (e.r(i), Object.defineProperty(i, "default", {
        enumerable: !0,
        value: t
      }), 2 & r && "string" != typeof t) for (var n in t) e.d(i, n, function (r) {
        return t[r];
      }.bind(null, n));
      return i;
    }, e.n = function (t) {
      var r = t && t.__esModule ? function () {
        return t.default;
      } : function () {
        return t;
      };
      return e.d(r, "a", r), r;
    }, e.o = function (t, r) {
      return Object.prototype.hasOwnProperty.call(t, r);
    }, e.p = "", e(e.s = 1);
  }([function (t, r) {
    var e = function () {
      "use strict";

      function t(t, r) {
        var e = t.l + r.l,
          i = {
            h: t.h + r.h + (e / 2 >>> 31) >>> 0,
            l: e >>> 0
          };
        t.h = i.h, t.l = i.l;
      }
      function r(t, r) {
        t.h ^= r.h, t.h >>>= 0, t.l ^= r.l, t.l >>>= 0;
      }
      function e(t, r) {
        var e = {
          h: t.h << r | t.l >>> 32 - r,
          l: t.l << r | t.h >>> 32 - r
        };
        t.h = e.h, t.l = e.l;
      }
      function i(t) {
        var r = t.l;
        t.l = t.h, t.h = r;
      }
      function n(n, o, a, u) {
        t(n, o), t(a, u), e(o, 13), e(u, 16), r(o, n), r(u, a), i(n), t(a, o), t(n, u), e(o, 17), e(u, 21), r(o, a), r(u, n), i(a);
      }
      function o(t, r) {
        return t[r + 3] << 24 | t[r + 2] << 16 | t[r + 1] << 8 | t[r];
      }
      function a(t, e) {
        "string" == typeof e && (e = u(e));
        var i = {
            h: t[1] >>> 0,
            l: t[0] >>> 0
          },
          a = {
            h: t[3] >>> 0,
            l: t[2] >>> 0
          },
          _ = {
            h: i.h,
            l: i.l
          },
          f = i,
          l = {
            h: a.h,
            l: a.l
          },
          s = a,
          c = e.length,
          h = c - 7,
          g = new Uint8Array(new ArrayBuffer(8));
        r(_, {
          h: 1936682341,
          l: 1886610805
        }), r(l, {
          h: 1685025377,
          l: 1852075885
        }), r(f, {
          h: 1819895653,
          l: 1852142177
        }), r(s, {
          h: 1952801890,
          l: 2037671283
        });
        for (var v = 0; v < h;) {
          var p = {
            h: o(e, v + 4),
            l: o(e, v)
          };
          r(s, p), n(_, l, f, s), n(_, l, f, s), r(_, p), v += 8;
        }
        g[7] = c;
        for (var d = 0; v < c;) g[d++] = e[v++];
        for (; d < 7;) g[d++] = 0;
        var b = {
          h: g[7] << 24 | g[6] << 16 | g[5] << 8 | g[4],
          l: g[3] << 24 | g[2] << 16 | g[1] << 8 | g[0]
        };
        r(s, b), n(_, l, f, s), n(_, l, f, s), r(_, b), r(f, {
          h: 0,
          l: 255
        }), n(_, l, f, s), n(_, l, f, s), n(_, l, f, s), n(_, l, f, s);
        var y = _;
        return r(y, l), r(y, f), r(y, s), y;
      }
      function u(t) {
        if ("function" == typeof TextEncoder) return new TextEncoder().encode(t);
        t = unescape(encodeURIComponent(t));
        for (var r = new Uint8Array(t.length), e = 0, i = t.length; e < i; e++) r[e] = t.charCodeAt(e);
        return r;
      }
      return {
        hash: a,
        hash_hex: function hash_hex(t, r) {
          var e = a(t, r);
          return ("0000000" + e.h.toString(16)).substr(-8) + ("0000000" + e.l.toString(16)).substr(-8);
        },
        hash_uint: function hash_uint(t, r) {
          var e = a(t, r);
          return 4294967296 * (2097151 & e.h) + e.l;
        },
        string16_to_key: function string16_to_key(t) {
          var r = u(t);
          if (16 !== r.length) throw Error("Key length must be 16 bytes");
          var e = new Uint32Array(4);
          return e[0] = o(r, 0), e[1] = o(r, 4), e[2] = o(r, 8), e[3] = o(r, 12), e;
        },
        string_to_u8: u
      };
    }();
    (t = t || {}).exports = e;
  }, function (t, r, e) {
    "use strict";

    e.r(r);
    var i = e(0),
      n = e.n(i);
    function o(t) {
      return (o = "function" == typeof Symbol && "symbol" == _typeof(Symbol.iterator) ? function (t) {
        return _typeof(t);
      } : function (t) {
        return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : _typeof(t);
      })(t);
    }
    function a(t, r) {
      for (var e = 0; e < r.length; e++) {
        var i = r[e];
        i.enumerable = i.enumerable || !1, i.configurable = !0, "value" in i && (i.writable = !0), Object.defineProperty(t, i.key, i);
      }
    }
    function u(t, r) {
      return !r || "object" !== o(r) && "function" != typeof r ? function (t) {
        if (void 0 === t) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        return t;
      }(t) : r;
    }
    function _(t) {
      var r = "function" == typeof Map ? new Map() : void 0;
      return (_ = function _(t) {
        if (null === t || (e = t, -1 === Function.toString.call(e).indexOf("[native code]"))) return t;
        var e;
        if ("function" != typeof t) throw new TypeError("Super expression must either be null or a function");
        if (void 0 !== r) {
          if (r.has(t)) return r.get(t);
          r.set(t, i);
        }
        function i() {
          return f(t, arguments, c(this).constructor);
        }
        return i.prototype = Object.create(t.prototype, {
          constructor: {
            value: i,
            enumerable: !1,
            writable: !0,
            configurable: !0
          }
        }), s(i, t);
      })(t);
    }
    function f(t, r, e) {
      return (f = l() ? Reflect.construct : function (t, r, e) {
        var i = [null];
        i.push.apply(i, r);
        var n = new (Function.bind.apply(t, i))();
        return e && s(n, e.prototype), n;
      }).apply(null, arguments);
    }
    function l() {
      if ("undefined" == typeof Reflect || !Reflect.construct) return !1;
      if (Reflect.construct.sham) return !1;
      if ("function" == typeof Proxy) return !0;
      try {
        return Date.prototype.toString.call(Reflect.construct(Date, [], function () {})), !0;
      } catch (t) {
        return !1;
      }
    }
    function s(t, r) {
      return (s = Object.setPrototypeOf || function (t, r) {
        return t.__proto__ = r, t;
      })(t, r);
    }
    function c(t) {
      return (c = Object.setPrototypeOf ? Object.getPrototypeOf : function (t) {
        return t.__proto__ || Object.getPrototypeOf(t);
      })(t);
    }
    var h = function (t) {
      !function (t, r) {
        if ("function" != typeof r && null !== r) throw new TypeError("Super expression must either be null or a function");
        t.prototype = Object.create(r && r.prototype, {
          constructor: {
            value: t,
            writable: !0,
            configurable: !0
          }
        }), r && s(t, r);
      }(h, t);
      var r,
        e,
        i,
        n,
        _,
        f = (r = h, e = l(), function () {
          var t,
            i = c(r);
          if (e) {
            var n = c(this).constructor;
            t = Reflect.construct(i, arguments, n);
          } else t = i.apply(this, arguments);
          return u(this, t);
        });
      function h(t, r) {
        var e;
        if (function (t, r) {
          if (!(t instanceof r)) throw new TypeError("Cannot call a class as a function");
        }(this, h), t > h.__kMaxLength) throw new RangeError("Maximum BigInt size exceeded");
        return (e = f.call(this, t)).sign = r, e;
      }
      return i = h, _ = [{
        key: "BigInt",
        value: function value(t) {
          if ("number" == typeof t) {
            if (0 === t) return h.__zero();
            if ((0 | t) === t) return t < 0 ? h.__oneDigit(-t, !0) : h.__oneDigit(t, !1);
            if (!Number.isFinite(t) || Math.floor(t) !== t) throw new RangeError("The number " + t + " cannot be converted to BigInt because it is not an integer");
            return h.__fromDouble(t);
          }
          if ("string" == typeof t) {
            var r = h.__fromString(t);
            if (null === r) throw new SyntaxError("Cannot convert " + t + " to a BigInt");
            return r;
          }
          if ("boolean" == typeof t) return !0 === t ? h.__oneDigit(1, !1) : h.__zero();
          if ("object" === o(t)) {
            if (t.constructor === h) return t;
            var e = h.__toPrimitive(t);
            return h.BigInt(e);
          }
          throw new TypeError("Cannot convert " + t + " to a BigInt");
        }
      }, {
        key: "toNumber",
        value: function value(t) {
          var r = t.length;
          if (0 === r) return 0;
          if (1 === r) {
            var e = t.__unsignedDigit(0);
            return t.sign ? -e : e;
          }
          var i = t.__digit(r - 1),
            n = h.__clz32(i),
            o = 32 * r - n;
          if (o > 1024) return t.sign ? -1 / 0 : 1 / 0;
          var a = o - 1,
            u = i,
            _ = r - 1,
            f = n + 1,
            l = 32 === f ? 0 : u << f;
          l >>>= 12;
          var s = f - 12,
            c = f >= 12 ? 0 : u << 20 + f,
            g = 20 + f;
          s > 0 && _ > 0 && (_--, l |= (u = t.__digit(_)) >>> 32 - s, c = u << s, g = s), g > 0 && _ > 0 && (_--, c |= (u = t.__digit(_)) >>> 32 - g, g -= 32);
          var v = h.__decideRounding(t, g, _, u);
          if ((1 === v || 0 === v && 1 == (1 & c)) && 0 == (c = c + 1 >>> 0) && ++l >>> 20 != 0 && (l = 0, ++a > 1023)) return t.sign ? -1 / 0 : 1 / 0;
          var p = t.sign ? 1 << 31 : 0;
          return a = a + 1023 << 20, h.__kBitConversionInts[1] = p | a | l, h.__kBitConversionInts[0] = c, h.__kBitConversionDouble[0];
        }
      }, {
        key: "remainder",
        value: function value(t, r) {
          if (0 === r.length) throw new RangeError("Division by zero");
          if (h.__absoluteCompare(t, r) < 0) return t;
          var e = r.__unsignedDigit(0);
          if (1 === r.length && e <= 65535) {
            if (1 === e) return h.__zero();
            var i = h.__absoluteModSmall(t, e);
            return 0 === i ? h.__zero() : h.__oneDigit(i, t.sign);
          }
          var n = h.__absoluteDivLarge(t, r, !1, !0);
          return n.sign = t.sign, n.__trim();
        }
      }, {
        key: "asIntN",
        value: function value(t, r) {
          if (0 === r.length) return r;
          if (0 === t) return h.__zero();
          if (t >= h.__kMaxLengthBits) return r;
          var e = t + 31 >>> 5;
          if (r.length < e) return r;
          var i = r.__unsignedDigit(e - 1),
            n = 1 << (t - 1 & 31);
          if (r.length === e && i < n) return r;
          if ((i & n) !== n) return h.__truncateToNBits(t, r);
          if (!r.sign) return h.__truncateAndSubFromPowerOfTwo(t, r, !0);
          if (0 == (i & n - 1)) {
            for (var o = e - 2; o >= 0; o--) if (0 !== r.__digit(o)) return h.__truncateAndSubFromPowerOfTwo(t, r, !1);
            return r.length === e && i === n ? r : h.__truncateToNBits(t, r);
          }
          return h.__truncateAndSubFromPowerOfTwo(t, r, !1);
        }
      }, {
        key: "__zero",
        value: function value() {
          return new h(0, !1);
        }
      }, {
        key: "__oneDigit",
        value: function value(t, r) {
          var e = new h(1, r);
          return e.__setDigit(0, t), e;
        }
      }, {
        key: "__decideRounding",
        value: function value(t, r, e, i) {
          if (r > 0) return -1;
          var n;
          if (r < 0) n = -r - 1;else {
            if (0 === e) return -1;
            e--, i = t.__digit(e), n = 31;
          }
          var o = 1 << n;
          if (0 == (i & o)) return -1;
          if (0 != (i & (o -= 1))) return 1;
          for (; e > 0;) if (e--, 0 !== t.__digit(e)) return 1;
          return 0;
        }
      }, {
        key: "__fromDouble",
        value: function value(t) {
          var r = t < 0;
          h.__kBitConversionDouble[0] = t;
          var e,
            i = (h.__kBitConversionInts[1] >>> 20 & 2047) - 1023,
            n = 1 + (i >>> 5),
            o = new h(n, r),
            a = 1048575 & h.__kBitConversionInts[1] | 1048576,
            u = h.__kBitConversionInts[0],
            _ = 31 & i,
            f = 0;
          if (_ < 20) {
            var l = 20 - _;
            f = l + 32, e = a >>> l, a = a << 32 - l | u >>> l, u <<= 32 - l;
          } else if (20 === _) f = 32, e = a, a = u;else {
            var s = _ - 20;
            f = 32 - s, e = a << s | u >>> 32 - s, a = u << s;
          }
          o.__setDigit(n - 1, e);
          for (var c = n - 2; c >= 0; c--) f > 0 ? (f -= 32, e = a, a = u) : e = 0, o.__setDigit(c, e);
          return o.__trim();
        }
      }, {
        key: "__isWhitespace",
        value: function value(t) {
          return t <= 13 && t >= 9 || (t <= 159 ? 32 === t : t <= 131071 ? 160 === t || 5760 === t : t <= 196607 ? (t &= 131071) <= 10 || 40 === t || 41 === t || 47 === t || 95 === t || 4096 === t : 65279 === t);
        }
      }, {
        key: "__fromString",
        value: function value(t) {
          var r = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 0,
            e = 0,
            i = t.length,
            n = 0;
          if (n === i) return h.__zero();
          for (var o = t.charCodeAt(n); h.__isWhitespace(o);) {
            if (++n === i) return h.__zero();
            o = t.charCodeAt(n);
          }
          if (43 === o) {
            if (++n === i) return null;
            o = t.charCodeAt(n), e = 1;
          } else if (45 === o) {
            if (++n === i) return null;
            o = t.charCodeAt(n), e = -1;
          }
          if (0 === r) {
            if (r = 10, 48 === o) {
              if (++n === i) return h.__zero();
              if (88 === (o = t.charCodeAt(n)) || 120 === o) {
                if (r = 16, ++n === i) return null;
                o = t.charCodeAt(n);
              } else if (79 === o || 111 === o) {
                if (r = 8, ++n === i) return null;
                o = t.charCodeAt(n);
              } else if (66 === o || 98 === o) {
                if (r = 2, ++n === i) return null;
                o = t.charCodeAt(n);
              }
            }
          } else if (16 === r && 48 === o) {
            if (++n === i) return h.__zero();
            if (88 === (o = t.charCodeAt(n)) || 120 === o) {
              if (++n === i) return null;
              o = t.charCodeAt(n);
            }
          }
          for (; 48 === o;) {
            if (++n === i) return h.__zero();
            o = t.charCodeAt(n);
          }
          var a = i - n,
            u = h.__kMaxBitsPerChar[r],
            _ = h.__kBitsPerCharTableMultiplier - 1;
          if (a > (1 << 30) / u) return null;
          var f = u * a + _ >>> h.__kBitsPerCharTableShift,
            l = f + 31 >>> 5,
            s = new h(l, !1),
            c = r < 10 ? r : 10,
            g = r > 10 ? r - 10 : 0;
          if (0 == (r & r - 1)) {
            u >>= h.__kBitsPerCharTableShift;
            var v = [],
              p = [],
              d = !1;
            do {
              for (var b = 0, y = 0;;) {
                var m = void 0;
                if (o - 48 >>> 0 < c) m = o - 48;else {
                  if (!((32 | o) - 97 >>> 0 < g)) {
                    d = !0;
                    break;
                  }
                  m = (32 | o) - 87;
                }
                if (y += u, b = b << u | m, ++n === i) {
                  d = !0;
                  break;
                }
                if (o = t.charCodeAt(n), y + u > 32) break;
              }
              v.push(b), p.push(y);
            } while (!d);
            h.__fillFromParts(s, v, p);
          } else {
            s.__initializeDigits();
            var w = !1,
              k = 0;
            do {
              for (var D = 0, C = 1;;) {
                var A = void 0;
                if (o - 48 >>> 0 < c) A = o - 48;else {
                  if (!((32 | o) - 97 >>> 0 < g)) {
                    w = !0;
                    break;
                  }
                  A = (32 | o) - 87;
                }
                var S = C * r;
                if (S > 4294967295) break;
                if (C = S, D = D * r + A, k++, ++n === i) {
                  w = !0;
                  break;
                }
                o = t.charCodeAt(n);
              }
              var j = u * k + (_ = 32 * h.__kBitsPerCharTableMultiplier - 1) >>> h.__kBitsPerCharTableShift + 5;
              s.__inplaceMultiplyAdd(C, D, j);
            } while (!w);
          }
          if (n !== i) {
            if (!h.__isWhitespace(o)) return null;
            for (n++; n < i; n++) if (o = t.charCodeAt(n), !h.__isWhitespace(o)) return null;
          }
          return 0 !== e && 10 !== r ? null : (s.sign = -1 === e, s.__trim());
        }
      }, {
        key: "__fillFromParts",
        value: function value(t, r, e) {
          for (var i = 0, n = 0, o = 0, a = r.length - 1; a >= 0; a--) {
            var u = r[a],
              _ = e[a];
            n |= u << o, 32 === (o += _) ? (t.__setDigit(i++, n), o = 0, n = 0) : o > 32 && (t.__setDigit(i++, n), n = u >>> _ - (o -= 32));
          }
          if (0 !== n) {
            if (i >= t.length) throw new Error("implementation bug");
            t.__setDigit(i++, n);
          }
          for (; i < t.length; i++) t.__setDigit(i, 0);
        }
      }, {
        key: "__toStringBasePowerOfTwo",
        value: function value(t, r) {
          var e = t.length,
            i = r - 1,
            n = i = ((i = ((i = (i >>> 1 & 85) + (85 & i)) >>> 2 & 51) + (51 & i)) >>> 4 & 15) + (15 & i),
            o = r - 1,
            a = t.__digit(e - 1),
            u = (32 * e - h.__clz32(a) + n - 1) / n | 0;
          if (t.sign && u++, u > 1 << 28) throw new Error("string too long");
          for (var _ = new Array(u), f = u - 1, l = 0, s = 0, c = 0; c < e - 1; c++) {
            var g = t.__digit(c),
              v = (l | g << s) & o;
            _[f--] = h.__kConversionChars[v];
            var p = n - s;
            for (l = g >>> p, s = 32 - p; s >= n;) _[f--] = h.__kConversionChars[l & o], l >>>= n, s -= n;
          }
          var d = (l | a << s) & o;
          for (_[f--] = h.__kConversionChars[d], l = a >>> n - s; 0 !== l;) _[f--] = h.__kConversionChars[l & o], l >>>= n;
          if (t.sign && (_[f--] = "-"), -1 !== f) throw new Error("implementation bug");
          return _.join("");
        }
      }, {
        key: "__absoluteCompare",
        value: function value(t, r) {
          var e = t.length - r.length;
          if (0 !== e) return e;
          for (var i = t.length - 1; i >= 0 && t.__digit(i) === r.__digit(i);) i--;
          return i < 0 ? 0 : t.__unsignedDigit(i) > r.__unsignedDigit(i) ? 1 : -1;
        }
      }, {
        key: "__internalMultiplyAdd",
        value: function value(t, r, e, i, n) {
          for (var o = e, a = 0, u = 0; u < i; u++) {
            var _ = t.__digit(u),
              f = h.__imul(65535 & _, r),
              l = (65535 & f) + a + o;
            o = l >>> 16;
            var s = h.__imul(_ >>> 16, r),
              c = (65535 & s) + (f >>> 16) + o;
            o = c >>> 16, a = s >>> 16, n.__setDigit(u, c << 16 | 65535 & l);
          }
          if (n.length > i) for (n.__setDigit(i++, o + a); i < n.length;) n.__setDigit(i++, 0);else if (o + a !== 0) throw new Error("implementation bug");
        }
      }, {
        key: "__absoluteModSmall",
        value: function value(t, r) {
          for (var e = 0, i = 2 * t.length - 1; i >= 0; i--) e = ((e << 16 | t.__halfDigit(i)) >>> 0) % r | 0;
          return e;
        }
      }, {
        key: "__absoluteDivLarge",
        value: function value(t, r, e, i) {
          var n = r.__halfDigitLength(),
            o = r.length,
            a = t.__halfDigitLength() - n,
            u = null;
          e && (u = new h(a + 2 >>> 1, !1)).__initializeDigits();
          var _ = new h(n + 2 >>> 1, !1);
          _.__initializeDigits();
          var f = h.__clz16(r.__halfDigit(n - 1));
          f > 0 && (r = h.__specialLeftShift(r, f, 0));
          for (var l = h.__specialLeftShift(t, f, 1), s = r.__halfDigit(n - 1), c = 0, g = a; g >= 0; g--) {
            var v = 65535,
              p = l.__halfDigit(g + n);
            if (p !== s) {
              var d = (p << 16 | l.__halfDigit(g + n - 1)) >>> 0;
              v = d / s | 0;
              for (var b = d % s | 0, y = r.__halfDigit(n - 2), m = l.__halfDigit(g + n - 2); h.__imul(v, y) >>> 0 > (b << 16 | m) >>> 0 && (v--, !((b += s) > 65535)););
            }
            h.__internalMultiplyAdd(r, v, 0, o, _);
            var w = l.__inplaceSub(_, g, n + 1);
            0 !== w && (w = l.__inplaceAdd(r, g, n), l.__setHalfDigit(g + n, l.__halfDigit(g + n) + w), v--), e && (1 & g ? c = v << 16 : u.__setDigit(g >>> 1, c | v));
          }
          return i ? (l.__inplaceRightShift(f), e ? {
            quotient: u,
            remainder: l
          } : l) : e ? u : void 0;
        }
      }, {
        key: "__clz16",
        value: function value(t) {
          return h.__clz32(t) - 16;
        }
      }, {
        key: "__specialLeftShift",
        value: function value(t, r, e) {
          var i = t.length,
            n = new h(i + e, !1);
          if (0 === r) {
            for (var o = 0; o < i; o++) n.__setDigit(o, t.__digit(o));
            return e > 0 && n.__setDigit(i, 0), n;
          }
          for (var a = 0, u = 0; u < i; u++) {
            var _ = t.__digit(u);
            n.__setDigit(u, _ << r | a), a = _ >>> 32 - r;
          }
          return e > 0 && n.__setDigit(i, a), n;
        }
      }, {
        key: "__toPrimitive",
        value: function value(t) {
          var r = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "default";
          if ("object" !== o(t)) return t;
          if (t.constructor === h) return t;
          var e = t[Symbol.toPrimitive];
          if (e) {
            var i = e(r);
            if ("object" !== o(i)) return i;
            throw new TypeError("Cannot convert object to primitive value");
          }
          var n = t.valueOf;
          if (n) {
            var a = n.call(t);
            if ("object" !== o(a)) return a;
          }
          var u = t.toString;
          if (u) {
            var _ = u.call(t);
            if ("object" !== o(_)) return _;
          }
          throw new TypeError("Cannot convert object to primitive value");
        }
      }, {
        key: "__truncateToNBits",
        value: function value(t, r) {
          for (var e = t + 31 >>> 5, i = new h(e, r.sign), n = e - 1, o = 0; o < n; o++) i.__setDigit(o, r.__digit(o));
          var a = r.__digit(n);
          if (0 != (31 & t)) {
            var u = 32 - (31 & t);
            a = a << u >>> u;
          }
          return i.__setDigit(n, a), i.__trim();
        }
      }, {
        key: "__truncateAndSubFromPowerOfTwo",
        value: function value(t, r, e) {
          for (var i = t + 31 >>> 5, n = new h(i, e), o = 0, a = i - 1, u = 0, _ = Math.min(a, r.length); o < _; o++) {
            var f = r.__digit(o),
              l = 0 - (65535 & f) - u,
              s = 0 - (f >>> 16) - (u = l >>> 16 & 1);
            u = s >>> 16 & 1, n.__setDigit(o, 65535 & l | s << 16);
          }
          for (; o < a; o++) n.__setDigit(o, 0 | -u);
          var c,
            g = a < r.length ? r.__digit(a) : 0,
            v = 31 & t;
          if (0 === v) {
            var p = 0 - (65535 & g) - u;
            c = 65535 & p | 0 - (g >>> 16) - (u = p >>> 16 & 1) << 16;
          } else {
            var d = 32 - v,
              b = 1 << 32 - d,
              y = (65535 & b) - (65535 & (g = g << d >>> d)) - u;
            c = 65535 & y | (b >>> 16) - (g >>> 16) - (u = y >>> 16 & 1) << 16, c &= b - 1;
          }
          return n.__setDigit(a, c), n.__trim();
        }
      }], (n = [{
        key: "__trim",
        value: function value() {
          for (var t = this.length, r = this[t - 1]; 0 === r;) r = this[--t - 1], this.pop();
          return 0 === t && (this.sign = !1), this;
        }
      }, {
        key: "__initializeDigits",
        value: function value() {
          for (var t = 0; t < this.length; t++) this[t] = 0;
        }
      }, {
        key: "__inplaceMultiplyAdd",
        value: function value(t, r, e) {
          e > this.length && (e = this.length);
          for (var i = 65535 & t, n = t >>> 16, o = 0, a = 65535 & r, u = r >>> 16, _ = 0; _ < e; _++) {
            var f = this.__digit(_),
              l = 65535 & f,
              s = f >>> 16,
              c = h.__imul(l, i),
              g = h.__imul(l, n),
              v = h.__imul(s, i),
              p = h.__imul(s, n),
              d = a + (65535 & c),
              b = u + o + (d >>> 16) + (c >>> 16) + (65535 & g) + (65535 & v);
            o = (a = (g >>> 16) + (v >>> 16) + (65535 & p) + (b >>> 16)) >>> 16, a &= 65535, u = p >>> 16;
            var y = 65535 & d | b << 16;
            this.__setDigit(_, y);
          }
          if (0 !== o || 0 !== a || 0 !== u) throw new Error("implementation bug");
        }
      }, {
        key: "__inplaceAdd",
        value: function value(t, r, e) {
          for (var i = 0, n = 0; n < e; n++) {
            var o = this.__halfDigit(r + n) + t.__halfDigit(n) + i;
            i = o >>> 16, this.__setHalfDigit(r + n, o);
          }
          return i;
        }
      }, {
        key: "__inplaceSub",
        value: function value(t, r, e) {
          var i = e - 1 >>> 1,
            n = 0;
          if (1 & r) {
            r >>= 1;
            for (var o = this.__digit(r), a = 65535 & o, u = 0; u < i; u++) {
              var _ = t.__digit(u),
                f = (o >>> 16) - (65535 & _) - n;
              n = f >>> 16 & 1, this.__setDigit(r + u, f << 16 | 65535 & a), n = (a = (65535 & (o = this.__digit(r + u + 1))) - (_ >>> 16) - n) >>> 16 & 1;
            }
            var l = t.__digit(u),
              s = (o >>> 16) - (65535 & l) - n;
            n = s >>> 16 & 1, this.__setDigit(r + u, s << 16 | 65535 & a);
            var c = l >>> 16;
            if (r + u + 1 >= this.length) throw new RangeError("out of bounds");
            0 == (1 & e) && (n = (a = (65535 & (o = this.__digit(r + u + 1))) - c - n) >>> 16 & 1, this.__setDigit(r + t.length, 4294901760 & o | 65535 & a));
          } else {
            r >>= 1;
            for (var h = 0; h < t.length - 1; h++) {
              var g = this.__digit(r + h),
                v = t.__digit(h),
                p = (65535 & g) - (65535 & v) - n,
                d = (g >>> 16) - (v >>> 16) - (n = p >>> 16 & 1);
              n = d >>> 16 & 1, this.__setDigit(r + h, d << 16 | 65535 & p);
            }
            var b = this.__digit(r + h),
              y = t.__digit(h),
              m = (65535 & b) - (65535 & y) - n;
            n = m >>> 16 & 1;
            var w = 0;
            0 == (1 & e) && (n = (w = (b >>> 16) - (y >>> 16) - n) >>> 16 & 1), this.__setDigit(r + h, w << 16 | 65535 & m);
          }
          return n;
        }
      }, {
        key: "__inplaceRightShift",
        value: function value(t) {
          if (0 !== t) {
            for (var r = this.__digit(0) >>> t, e = this.length - 1, i = 0; i < e; i++) {
              var n = this.__digit(i + 1);
              this.__setDigit(i, n << 32 - t | r), r = n >>> t;
            }
            this.__setDigit(e, r);
          }
        }
      }, {
        key: "__digit",
        value: function value(t) {
          return this[t];
        }
      }, {
        key: "__unsignedDigit",
        value: function value(t) {
          return this[t] >>> 0;
        }
      }, {
        key: "__setDigit",
        value: function value(t, r) {
          this[t] = 0 | r;
        }
      }, {
        key: "__halfDigitLength",
        value: function value() {
          var t = this.length;
          return this.__unsignedDigit(t - 1) <= 65535 ? 2 * t - 1 : 2 * t;
        }
      }, {
        key: "__halfDigit",
        value: function value(t) {
          return this[t >>> 1] >>> ((1 & t) << 4) & 65535;
        }
      }, {
        key: "__setHalfDigit",
        value: function value(t, r) {
          var e = t >>> 1,
            i = this.__digit(e),
            n = 1 & t ? 65535 & i | r << 16 : 4294901760 & i | 65535 & r;
          this.__setDigit(e, n);
        }
      }]) && a(i.prototype, n), _ && a(i, _), h;
    }(_(Array));
    h.__kMaxLength = 1 << 25, h.__kMaxLengthBits = h.__kMaxLength << 5, h.__kMaxBitsPerChar = [0, 0, 32, 51, 64, 75, 83, 90, 96, 102, 107, 111, 115, 119, 122, 126, 128, 131, 134, 136, 139, 141, 143, 145, 147, 149, 151, 153, 154, 156, 158, 159, 160, 162, 163, 165, 166], h.__kBitsPerCharTableShift = 5, h.__kBitsPerCharTableMultiplier = 1 << h.__kBitsPerCharTableShift, h.__kConversionChars = "0123456789abcdefghijklmnopqrstuvwxyz".split(""), h.__kBitConversionBuffer = new ArrayBuffer(8), h.__kBitConversionDouble = new Float64Array(h.__kBitConversionBuffer), h.__kBitConversionInts = new Int32Array(h.__kBitConversionBuffer), h.__clz32 = Math.clz32 || function (t) {
      return 0 === t ? 32 : 31 - (Math.log(t >>> 0) / Math.LN2 | 0) | 0;
    }, h.__imul = Math.imul || function (t, r) {
      return t * r | 0;
    };
    var g = h;
    function v(t, r) {
      for (var e = 0; e < r.length; e++) {
        var i = r[e];
        i.enumerable = i.enumerable || !1, i.configurable = !0, "value" in i && (i.writable = !0), Object.defineProperty(t, i.key, i);
      }
    }
    var p = function () {
      function t(r, e) {
        if (function (t, r) {
          if (!(t instanceof r)) throw new TypeError("Cannot call a class as a function");
        }(this, t), !r || "[object Array]" !== Object.prototype.toString.call(r)) throw Error("the params parameter of ABTEST must be Array");
        r.forEach(function (t) {
          if (!t || "[object Array]" !== Object.prototype.toString.call(t)) throw Error("ABTEST的参数abConfig必须为object组成的Array");
          for (var r = 0; r < t.length; r++) {
            if (!t[r] || "[object Object]" !== Object.prototype.toString.call(t[r])) throw Error("ABTEST的参数abConfig必须为object组成的Array");
            var e = t[r],
              i = e.oexp,
              n = e.params;
            if (!i || !n || "[object Object]" !== Object.prototype.toString.call(n)) throw Error("params参数必须是object");
            if (i.indexOf(".") < 0) throw Error("oexp参数格式有误");
            var o = n.groupId,
              a = n.g_trafficAlloc,
              u = n.salt,
              _ = n.v_trafficAlloc;
            if (!(o && u && a && "[object Array]" === Object.prototype.toString.call(a) && _ && "[object Array]" === Object.prototype.toString.call(_))) throw Error("params的参数有误");
            for (var f = 0; f < a.length; f++) {
              if (a[f].indexOf("~") < 0) throw Error("params的流量参数有误");
              var l = a[f].split("~")[0],
                s = a[f].split("~")[1];
              if (isNaN(Number(l)) || isNaN(Number(s)) || "" === l || "" === s || l > 1e4 || s > 1e4) throw Error("params的流量g_trafficAlloc参数有误");
            }
            for (var c = 0; c < _.length; c++) {
              if (_[c].indexOf("~") < 0) throw Error("params的流量参数有误");
              var h = _[c].split("~")[0],
                g = _[c].split("~")[1];
              if (isNaN(Number(h)) || isNaN(Number(g)) || "" === h || "" === g || h > 1e4 || g > 1e4) throw Error("params的流量v_trafficAlloc参数有误");
            }
          }
        }), this.params = r, this.mid = e, this.thisKey = e;
      }
      var r, e, i;
      return r = t, (e = [{
        key: "config",
        value: function value() {
          return this.getConfig();
        }
      }, {
        key: "to64bit",
        value: function value(t) {
          var r = n.a.hash_hex([0, 0, 0, 0], t);
          return g.toNumber(g.remainder(g.asIntN(64, g.BigInt("0x".concat(r))), g.BigInt(1e4)));
        }
      }, {
        key: "getConfig",
        value: function value() {
          var t = this;
          return this.params.map(function (r) {
            for (var e = 0; e < r.length; e++) for (var i = r[e], n = i.oexp, o = i.params, a = o.groupId, u = o.g_trafficAlloc, _ = o.salt, f = o.v_trafficAlloc, l = (t.to64bit("".concat(t.mid).concat(a)) + 1e4) % 1e4, s = 0; s < u.length; s++) {
              var c = u[s],
                h = c.split("~")[0],
                g = c.split("~")[1];
              if (Number(h) <= l && l <= Number(g)) {
                var v = (t.to64bit("".concat(t.mid).concat(a, ".").concat(_)) + 1e4) % 1e4,
                  p = f[0],
                  d = p.split("~")[0],
                  b = p.split("~")[1];
                if (Number(d) <= v && v <= Number(b)) return n;
                if (e === r.length - 1) return "";
              }
            }
            return "";
          });
        }
      }]) && v(r.prototype, e), i && v(r, i), t;
    }();
    r.default = p;
    window.ABTEST = p;
  }]);
});