import keyMap from '@client/common/lang/keyMap.json';
import addUrlParams from '@client/common/utils/addUrlParams';
import getUrlParams, { getUrlParmasLast } from '@client/common/utils/getUrlParams';
import ua from '@client/common/utils/ua.js';
var onlyPcRoute = [];
var onlyWapRoute = [];
var createReg = function createReg(path) {
  return new RegExp("".concat(path.replace(/:[\w-_]+(?=\/?)/, function () {
    return '[\\w-_]+';
  }), "$"));
};
var getCurRoutePath = function getCurRoutePath(pathname, routerArry) {
  return Object.values(routerArry).find(function (route) {
    return pathname.match(createReg(route));
  });
};

/**
 * 特殊处理需要保留参数的url
 * @param {string} pathName 
 */
var keepParamsWhichInUrl = function keepParamsWhichInUrl(pathname) {
  var result = null;
  if (pathname === '/search') {
    var kw = getUrlParams('kw');
    var label = getUrlParams('label');
    if (kw && label) {
      result = {
        kw: kw,
        label: label
      };
    }
    return result;
  }
  if (/^(\/user\/)/.test(pathname)) {
    var _label = getUrlParams('label');
    if (_label) {
      result = {
        label: _label
      };
    }
    return result;
  }
};

/**
 * 1. 获取去掉参数的url
 * 2. 特殊处理xu
 * @params origin -域名
 */
var getUrlWithOutParmas = function getUrlWithOutParmas() {
  var origin = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : window.location.origin;
  var pathname = window.location.pathname;
  var curUrl = "".concat(origin).concat(pathname);
  var params = keepParamsWhichInUrl(pathname);
  if (params) {
    curUrl = addUrlParams(curUrl, params);
  }
  return curUrl;
};

/**
 * 动态添加<Link>标签
 * @param {Object} attribute - 标签的属性对象
 */
var addLinkMeta = function addLinkMeta(attribute) {
  if (Object.prototype.toString.call(attribute) === '[object Object]') {
    var attrs = Object.keys(attribute) || [];
    if (attrs.length > 0) {
      var head = document.getElementsByTagName('head')[0];
      var linkTag = document.createElement('link');
      attrs.forEach(function (attr) {
        linkTag.setAttribute(attr, attribute[attr]);
      });
      head.appendChild(linkTag);
    }
  }
};

/**
 * 设置备用网页地址和多语言地址
 */
export var setHreflangInHead = function setHreflangInHead() {
  var curUrl = getUrlWithOutParmas();
  var alternateLists = document.querySelectorAll('link[rel="alternate"]');
  var setLangUrl = function setLangUrl() {
    // eslint-disable-next-line guard-for-in
    for (var key in keyMap) {
      var urlWithLang = addUrlParams(curUrl, {
        _lang: key
      });
      addLinkMeta({
        rel: 'alternate',
        hreflang: keyMap[key].split('-')[0],
        href: urlWithLang
      });
    }
  };
  if (alternateLists.length === 0) {
    addLinkMeta({
      rel: 'alternate',
      hreflang: 'x-default',
      href: curUrl
    });
    setLangUrl();
    return;
  }
  var formatKeyMap = Object.values(keyMap).map(function (e) {
    return e.split('-')[0];
  });
  var keyMapkeys = Object.keys(keyMap);
  alternateLists.forEach(function (e) {
    var j = Object.values(formatKeyMap).indexOf(e === null || e === void 0 ? void 0 : e.hreflang);
    if (j > -1) {
      e.href = addUrlParams(curUrl, {
        _lang: keyMapkeys[j]
      });
    } else if ((e === null || e === void 0 ? void 0 : e.hreflang) === 'x-default') {
      e.href = curUrl;
    }
  });
};

/**
 * 设置标准链接：
 * 1. www 指向当前地址；
 * 2. m指向www；
 * 3. url中只保留语言参数;
 * 4. 若m有，www没有时，不需要执行这个
 */
export var setCanonicalUrl = function setCanonicalUrl(replace, beReplaced) {
  var pathname = window.location.pathname;
  var onlyWap = getCurRoutePath(pathname, onlyWapRoute);
  // 如果路由只在m站存在，检测是否有www的标准指向，如有需要删除
  if (onlyWap && canonicalLists.length !== 0) {
    var head = document.getElementsByTagName('head')[0];
    head.removeChild(canonicalLists[0]);
    return false;
  }

  // 只有m的路由不需要加
  if (onlyWap) {
    return false;
  }
  var curUrl = getUrlWithOutParmas("https://".concat(DOMAINS.maindomain));
  if (replace && beReplaced) {
    curUrl = String(curUrl).replace(beReplaced, replace);
  }
  var langParam = getUrlParmasLast('_lang');
  var canonicalLists = document.querySelectorAll('link[rel="canonical"]');
  if (langParam) {
    curUrl = addUrlParams(curUrl, {
      _lang: langParam
    });
  }
  if (canonicalLists.length === 0) {
    addLinkMeta({
      rel: 'canonical',
      href: curUrl
    });
  } else {
    canonicalLists[0].href = curUrl;
  }
  return true;
};

/**
 * 添加media属性
 * 1. 如果www有，m没有时，不需要执行
 */
export var setMediaLink = function setMediaLink() {
  var pathname = window.location.pathname;
  if (getCurRoutePath(pathname, onlyPcRoute)) {
    return false;
  }
  var curUrl = getUrlWithOutParmas("https://".concat(DOMAINS.wapdomain));
  var langParam = getUrlParmasLast('_lang');
  var rules = "only screen and (max-width: 640px)";
  var canonicalLists = document.querySelectorAll("link[media=\"".concat(rules, "\"]"));
  if (langParam) {
    curUrl = addUrlParams(curUrl, {
      _lang: langParam
    });
  }
  if (canonicalLists.length === 0) {
    addLinkMeta({
      rel: 'alternate',
      media: rules,
      href: curUrl
    });
  } else {
    canonicalLists[0].href = curUrl;
  }
  return true;
};

/**
 * 1. robots.txt 排除的页面不需要添加;
 * @params props.isSingleLang - 表明是否是单一语言
 */
export var setStandardUrlBothPcAndWap = function setStandardUrlBothPcAndWap(_ref) {
  var _ref$isSingleLang = _ref.isSingleLang,
    isSingleLang = _ref$isSingleLang === void 0 ? false : _ref$isSingleLang;
  setCanonicalUrl();
  if (ua.isDesktop || ua.isTv) {
    setMediaLink();
  }
  if (!isSingleLang) {
    setHreflangInHead();
  }
};

/**
 * robots.txt 排除的页面不需要添加
 * @params isSingleLang - 表明是否是单一语言
 */
export var setStandardUrlJustPc = function setStandardUrlJustPc(_ref2) {
  var _ref2$isSingleLang = _ref2.isSingleLang,
    isSingleLang = _ref2$isSingleLang === void 0 ? false : _ref2$isSingleLang;
  setCanonicalUrl();
  if (!isSingleLang) {
    setHreflangInHead();
  }
};

/**
 * robots.txt 排除的页面不需要添加
 * @params isSingleLang - 表明是否是单一语言
 */
export var setStandardUrlJustWap = function setStandardUrlJustWap(_ref3) {
  var _ref3$isSingleLang = _ref3.isSingleLang,
    isSingleLang = _ref3$isSingleLang === void 0 ? false : _ref3$isSingleLang;
  if (!isSingleLang) {
    setHreflangInHead();
  }
};
export var getLangUrl = function getLangUrl() {
  return setHreflangInHead();
};