export var sphdExp = '3891.5573';
export default [[{
  params: {
    groupId: '3071',
    g_trafficAlloc: ['0~9999'],
    salt: '72907319',
    v_trafficAlloc: ['0~4999']
  },
  oexp: '3891.5572'
}, {
  params: {
    groupId: '3071',
    g_trafficAlloc: ['0~9999'],
    salt: '72907319',
    v_trafficAlloc: ['5000~9999']
  },
  oexp: sphdExp
}]];