import Auth from '@desktop/src/common/services/auth';
export default (function () {
  if (Auth.checkIsAutoLogin()) {
    // 正在自动登录
    return false;
  } else if (Auth.checkLogin()) {
    // 已登录
    return Auth.getUserInfo();
  } else {
    // 未登录
    window.location = "//".concat(DOMAINS.maindomain);
  }
});