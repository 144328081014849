/**
 * 路由路径配置
 * index 模块主页面路由
 * langKey 模块 title key
 * 个人中心的路由暂时注释，换为多页测试动态初始化数据上报。测试OK后再改回来
 */
export var routePathConf = {
  index: {
    index: '/',
    lang: {
      t: 'titles.index'
      // d: 'descriptions.index'
    }
  },
  games: {
    index: '/games',
    lang: {
      t: 'titles.games'
      // d: 'descriptions.games'
    }
  },
  lives: {
    index: '/lives',
    lang: {
      t: 'titles.lives'
      // d: 'descriptions.lives'
    }
  },
  game: {
    index: '/game/:id',
    lang: {},
    bodyClass: 'page-m-game'
  },
  list: {
    index: '/list/:id',
    lang: {
      // 需要页面内获取完数据才能进行替换，故此处继续使用默认值
      t: 'titles.index'
      // d: 'descriptions.index'
    }
  },
  lang: {
    index: '/lang',
    lang: {
      t: 'titles.index'
      // d: 'descriptions.index'
    }
  },
  clang: {
    index: '/clang',
    lang: {
      t: 'titles.index'
      // d: 'descriptions.index'
    }
  },
  ccountry: {
    index: '/ccountry',
    lang: {
      t: 'titles.index'
      // d: 'descriptions.index'
    }
  },
  channel: {
    index: '/c/:id',
    langKey: {}
  },
  article: {
    index: '/a/:id',
    langKey: {
      // t: 'titles.index',
      // d: 'descriptions.index'
    }
  },
  clip: {
    index: '/clip/:id',
    lang: {
      // t: 'titles.clip',
      // d: 'descriptions.clip'
    }
  },
  v: {
    index: '/v/:id',
    lang: {
      // t: 'titles.clip',
      // d: 'descriptions.clip'
    }
  },
  family: {
    index: '/fam',
    needLogin: true,
    list: {
      index: '/fam/list'
    },
    create: {
      index: '/fam/create'
    },
    home: {
      index: '/fam/home',
      needLogin: false
    },
    members: {
      index: '/fam/member'
    },
    memberDelete: {
      index: '/fam/member/delete'
    },
    memberReview: {
      index: '/fam/member/review'
    }
  },
  icenter: {
    index: '/i',
    liveRecord: {
      index: '/live-record',
      lang: {}
    },
    family: {
      index: '/i/fam',
      needLogin: true,
      list: {
        index: '/i/fam/list'
      },
      create: {
        index: '/i/fam/create'
      },
      home: {
        index: '/i/fam/home',
        needLogin: false
      },
      members: {
        index: '/i/fam/member'
      },
      memberDelete: {
        index: '/i/fam/member/delete'
      },
      memberReview: {
        index: '/i/fam/member/review'
      }
    },
    anchorLevel: {
      index: '/anchor-level',
      lang: {
        t: 'titles.anchorLevel'
      }
    },
    fansClub: {
      index: '/fans-club',
      lang: {
        t: 'titles.fansClub'
      }
    },
    discord: {
      index: '/discord-setting',
      auth_wizard: '/discord-auth-wizard',
      setting: '/discord-setting'
    },
    video: {
      index: '/video'
    },
    clanOperate: {
      index: '/clan-operate',
      transactionDetail: '/clan-operate/transaction-detail',
      commissionDetail: '/clan-operate/commission-detail',
      streamerDetail: '/clan-operate/streamer-detail',
      streamerOnliveList: '/clan-operate/streamer-onlive-list',
      streamerPage: '/clan-operate/streamer-detail/:id',
      streamerTransactionDetail: '/clan-operate/streamer-transaction-detail'
    },
    addNewAnchorBroadcast: {
      index: '/new-anchor-broadcast'
    },
    houseManage: {
      index: '/house-manage',
      lang: {
        t: 'titles.bannedAdmin'
        // d: 'descriptions.index'
      }
    },
    audioRoomBanManage: {
      index: '/audio-room-ban-manage',
      lang: {
        t: 'titles.audioRoomBanList'
        // d: 'descriptions.index'
      }
    },
    clanContract: {
      index: '/clan-contract',
      review: '/clan-contract/review'
    },
    clanAuthority: {
      index: '/clan-authority',
      edit: '/clan-authority/edit/:id?'
    },
    clanIntroduction: {
      index: '/clan-introduction'
    },
    clanInformation: {
      index: '/clan-information'
    },
    clanDurationTask: {
      index: '/clan-duration-task'
    },
    anchorTasks: {
      index: '/anchor-tasks',
      chart: '/anchor-tasks/chart'
    },
    pickMe: {
      index: '/pick-me',
      bodyClass: 'm-pick-me-page'
    },
    subscribedStreamer: {
      index: '/subscribed-streamer'
    },
    myGrowth: {
      index: '/my-growth'
    },
    socialLink: {
      index: '/social-link',
      lang: {
        t: 'titles.socialLink'
      }
    }
  },
  user: {
    index: '/user/:id',
    lang: {
      // t: 'titles.index',
      // d: 'descriptions.index'
    },
    bodyClass: 'page-user'
  },
  topFans: {
    index: '/user/:id/topfans',
    lang: {
      // t: 'titles.index',
      // d: 'descriptions.index'
    },
    bodyClass: 'page-topfans'
  },
  appeal: {
    index: '/appeal'
  },
  appealCheck: {
    index: '/appeal-check'
  },
  clanHome: {
    index: '/clan/:id',
    langKey: {
      // d: 'descriptions.index'
    }
  },
  ranks: {
    index: '/ranks',
    lang: {
      t: ''
      // d: 'descriptions.ranks'
    }
  },
  halloffame: {
    index: '/halloffame'
  },
  search: {
    index: '/search',
    langKey: {
      t: 'titles.index'
      // d: 'descriptions.index'
    }
  },
  activities: {
    index: '/activities',
    lang: {
      t: 'titles.activities'
      // d: 'descriptions.activities'
    }
  },
  activity: {
    index: '/activity/:id',
    lang: {},
    bodyClass: 'page-m-activity'
  },
  matches: {
    index: '/esports',
    createReg: function createReg() {
      return /^\/esports(?:\/game\/(\d|[a-zA-Z])+)?\/?$/;
    }
  },
  mall: {
    index: '/mall',
    exchangeRecord: '/mall/exchange-record'
  },
  viewer: {
    index: '/viewer/:id/level',
    lang: {
      // t: 'titles.userLevel'
      // d: 'descriptions.index'
    },
    bodyClass: 'page-viewer'
  },
  viewerReward: {
    index: '/viewer/reward',
    bodyClass: 'page-viewer-reward'
  }
};