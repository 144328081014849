import eventEmitter from '@nimo-fed/events';
var EVENTS = {
  PLAYER_TECH_LOADED: 'player.tech.ready',
  // videojs tech 加载成功
  PLAYER_LOAD_TIMEOUT: 'player.load.timeout',
  // 播放器 拉流超时
  PLAYER_LOAD_ERROR: 'player.load.error',
  // 播放器 拉流 错误
  PLAYER_STAT_INFO: 'player.stat.info',
  // 添加编码帧（即播放器源数据有更新）
  PLAYER_STREAM_CHANGE: 'player.stream.change',
  // 更改流信息事件
  /*
   * event: PLAYER_CHANGE_LOCK_STATE
   * desc：播放器已锁定状态被修改的事件通知
   * data: {
   *          namespace: <string> - 触发的状态所在命名空间
   *          key: <string>       - 触发的状态名
   *          value: <any>        - 触发的状态新值
   *       }
   */
  PLAYER_CHANGE_LOCK_STATE: 'player.change.lock.state',
  CLIPBOARD_COPY: 'clipboard.copy',
  // 复制成功
  ROOMCHAT_RECV: 'taf.roomchat.recv',
  // 接收消息
  ROOMCHAT_SEND: 'taf.roomchat.send',
  // 发送消息
  GIFT_LIST_RECV: 'taf.gift.recv',
  // 接收礼物列表成功
  LIVE_ROOM_SHARE: 'live.room.share',
  // 唤起直播间分享弹窗
  LIVE_SHARED: 'live.shared',
  // 直播间分享回调
  LIVE_STREAM_STOP: 'live.stream.stop',
  // 断流，用在 wap 直播间导量及 pc 的引导关注
  LIVE_STREAM_NEW: 'live.stream.new',
  // 推流，用在 wap 直播间导量及 pc 的引导关注
  PLAYBACK_NOTIFY: 'playback.notify',
  // 回放下发，用于控制 不在播封面&赛事异步场景&直播标题前缀 开关
  // LIVE_ROOM_FOLLOW: 'live.room.follow', // 直播间内关注/取消关注主播（PC）
  THEME_CHANGE: 'theme.change',
  // 主题更换事件
  SIDEBAR_LOCATION: 'sidebar.location',
  // 设置左侧侧边栏选中态
  PROFILE_LANG_CHANGE: 'profilelang.change',
  // 个人中心操作语言切换事件
  PLAYER_FULLSCREEN: 'palyer.fullscreen',
  // 播放器 进入 全屏事件
  PLAYER_EXIT_FULLSCREEN: 'palyer.exit_fullscreen',
  // 播放器 进入 退出全屏事件
  FOLLOW: 'follow',
  // 全局关注事件
  UNFOLLOW: 'unfollow',
  // 全局取消关注事件
  FANS_GROUP_CONSUME_FANS_GIFT: 'fansGroup.consumeFansGift',
  // 粉丝团消费办卡粉丝礼物时间
  FANS_GROUP_BADGE_LIST_UPDATE: 'fansGroup.badgeListUpdate',
  // 粉丝团我的徽章列表
  ROOMCHAT_TOP_GUIDE_MSG: 'taf.roomchat.topGuideMsg',
  // 公屏置顶消息
  TEXT_BULLETSCREEN_SETTINGS: 'text.bulletscreen.settings',
  // 弹幕配置
  // 'SIDEBAR_COLLAPSE_CHANGE': 'sidebar.collapse.change', // 侧边栏 展开/收起 事件
  LIVE_INTERACTIVE_POP_OUT: 'live.interactive.pop.out',
  // 直播间互动玩法显示，具体模块传参 mod 区分
  ROOM_SEND_MSG_SUCCEED: 'room.send.msg.succeed',
  ROOM_SEND_GIFT_STATUS: 'room.send.gift.status',
  TAF_MESSAGE_RECEIVE: 'taf.message.receive',
  // 收到 TAF 广播消息
  BSLOTTERY_STATE: 'bslottery.state',
  GLOBAL_BUSINESS_MESSAGE: 'global.business.message',
  // 通用业务消息通知
  SHOW_USER_INFO_DIALOG: 'show.user.info.dialog',
  // 显示用户信息弹窗
  POPUP_JOIN_FANS_MODAL: 'fansGroup.popup.joinFansModal',
  // 弹出加入粉丝团弹窗
  POPUP_PAID_SUBSCRIPTION_MODAL: 'room.subscribePay.pop',
  // 弹出付费订阅弹窗
  OPEN_WEEK_STAR_ACTIVITY_MODAL: 'room.week.star.activity.modal',
  // 打开周星活动页弹窗
  POPUP_FANS_PRIVILEGE_POPOVER: 'fansGroup.popup.fansPrivilegePopover',
  // 弹出粉丝特权介绍弹窗
  AT_USER_IN_BARRAGE_OR_IM: 'chat.at.user.barrage',
  // 弹幕输入框@特定用户
  POPUP_FORTUNE_GIFT_MODAL: 'fortuneGift.popup.fortuneGiftModal',
  // 弹出幸运礼物弹窗
  UPDATE_ACCOUNT_BALANCE: 'room.account.balance.update',
  // 更新账户余额
  POPOUT_SOCIAL_MEDIA: 'social.media.popout',
  // 弹出社媒绑定页
  ROOM_INTERACTIVE_MODULE_POPOUT: 'room.interactive.module.popout',
  // 弹出直播间互动模块
  ROOM_INTERACTIVE_GIFT_MODULE_POPOUT: 'room.interactiveGift.module.popout',
  // 弹出直播间互动礼物模块
  CHATBOX_FILL_CONTENT: 'room.chatbox.fillcontent',
  // 弹入chatbox消息
  DASHBOARD_OPEN_ACTION: 'dashboard.open.action',
  // dashboard 打开 quick action
  DASHBOARD_OPEN_FEEDBACK: 'dashboard.open.feedback',
  // dashboard 打开 意见反馈
  TEXT_FORBID: 'TEXT_FORBID',
  // 公屏禁止发言
  DASHBOARD_OPEN_LEVEL_UP: 'dashboard.open.level_up',
  // dashboard 打开升级窗口
  POPUP_WEEKLY_STAR_MODAL: 'weeklyStar.popup.modal',
  // 弹出周星弹窗
  OPEN_FINAL_BOX: 'revenue.open.final.modal',
  // web直播间，打开2021年度总决赛红包面板
  REPLY_COMMENT: 'reply.comment',
  // 进行回复评论
  REPLY_COMMENT_SUCCESS: 'reply.comment.success',
  // 成功回复评论
  OPEN_FANS_BOX: 'revenue.open.fans.modal',
  // web直播间，打开平台粉丝日活动红包面板
  GIFT_PACK_TIP: 'GIFT_PACK_TIP',
  SHOW_ANCHOR_DAILY_TASK_MODAL: 'room.anchorDailyTask.modal.show',
  // 直播间展示主播每日任务页面弹窗
  DASHBOARD_GRAPHIC_CONFIG_DATA: 'dashboard.graphic.config.data',
  // dashboard侧边菜单栏左下角位置、home页推荐位置、home页idea位置等图文配置数据更新
  PERSONAL_COMMENT: 'personal.comment',
  // nimo个人页页面点击评论图标
  PERSONAL_DEL_COMMENT: 'personal.del.comment',
  // nimo个人页删除评论
  OPEN_BOSS_COMING_PANEL: 'open.boss.coming.panel',
  OPEN_RAMADAN_BOX: 'revenue.open.ramadan.modal',
  // web直播间，打开斋月活动面板
  OPEN_RAMADAN_23_BOX: 'revenue.open.ramadan.23.modal',
  // web直播间，打开斋月活动面板 2023
  ANMOSID_UPDATE: 'anmosid.update',
  // 匿名uid更新
  GIFT_SENDED: 'GIFT_SENDED',
  // 送礼成功
  OPEN_WORLD_CUP_MODAL: 'revenue.open.world.cup.modal',
  // web直播间打开世界杯活动弹窗
  OPEN_ROOM_GIFT_PANEL: 'room.giftshop.tab.open',
  OPEN_TOP_CP: 'revenue.open.open.top_cp',
  OPEN_ROOM_GIFT_ITEM: 'room.giftshop.tab.openGiftItem',
  CLOSE_ROOM_GIFT_ITEM: 'room.giftshop.tab.closeGiftItem',
  PLAYER_VOLUME_CHANGE: 'player.volume.change',
  FOCUS_CHAT_INPUT: 'focus.room.chat.input',
  PLAYER_VIDEO_CURRENT_TIME_CHANGE: 'player.video.current_time.change' // 视频进度条更新
};

/**
 * eventEmitter.emit(eventName:string|symbol, [...args:any]): boolean
 * eg: eventEmitter.emit(EVENTS.WS_CONNECT, {reconnectCount: 2, usedTime: 2030, ...});
 */

/**
 * eventEmitter.on(eventName:string|symbol, listener:function): eventEmitter
 * eventEmitter.addListener(eventName:string|symbol, listener:function): eventEmitter
 * eg: eventEmitter.on(EVENTS.WS_CONNECT, ({reconnectCount, usedTime}) => {console.log(reconnectCount)});
 */

/**
 * eventEmitter.off(eventName:string|symbol, listener:function): eventEmitter //如果不传第二个参数(listener)，则删除同名事件所有事件订阅
 * eventEmitter.removeListener(eventName:string|symbol, listener:function): eventEmitter
 * eg: eventEmitter.off(EVENTS.WS_CONNECT, onWSConnect);
 */

/**
 * eventEmitter.once(eventName:string|symbol, listener:function): eventEmitter
 * eg: eventEmitter.once(EVENTS.WS_CONNECT, ({reconnectCount, usedTime}) => {console.log(reconnectCount)});
 */
var ONE_TIME_EVENTS = [EVENTS.SIDEBAR_LOCATION];
eventEmitter.addOneTimePersistentListener(ONE_TIME_EVENTS);
export { eventEmitter, EVENTS };