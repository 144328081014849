import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
// action type
// import CommonLang from '@client/common/lang/main';
import { loadLangPkg, normalizeLangRegistry } from '@client/common/lang';
export var UPDATE_COMMON_LANGPKG = 'update_common_langpkg';
export var UPDATE_LANG = 'update_lang';
export var GET_CLANG_LIST = 'get_clang_list';
export var UPDATE_CLANG = 'update_clang';
export var UPDATE_USER_INFO = 'update_user_info_common';
export var GET_DECORATION_INFO_LIST = 'get_decoration_info_list';

// action creators
// 更新common公用多语言
export var updateCommonLangPkg = function updateCommonLangPkg(id) {
  return function (dispatch) {
    return Promise.all([require('@client/common/lang/header'), require('@client/common/lang/error_code'), require('@client/common/lang/base'), require('@client/common/lang/rank')].map(function (m) {
      return loadLangPkg(m, id);
    })).then(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 4),
        header = _ref2[0],
        errorCode = _ref2[1],
        base = _ref2[2],
        rank = _ref2[3];
      dispatch({
        type: UPDATE_COMMON_LANGPKG,
        payload: {
          header: header,
          errorCode: errorCode,
          base: base,
          rank: rank
        }
      });
    });
  };
};
export var updateCommonModuleLangPkgByRegistry = function updateCommonModuleLangPkgByRegistry(registries, lang) {
  return function (dispatch) {
    var langRegistries = Array.isArray(registries) ? registries : [registries];
    var payload = {};
    return Promise.all(langRegistries.map(function (_registry) {
      var registry = normalizeLangRegistry(_registry);
      if (registry) {
        var k = registry.namespace.replace(/^common_/, '');
        return loadLangPkg(registry, lang).then(function (langPkg) {
          payload[k] = langPkg;
        });
      }
      return null;
    })).then(function () {
      dispatch({
        type: UPDATE_COMMON_LANGPKG,
        payload: payload
      });
    });
  };
};

// 更新语言ID
export var updateLang = function updateLang(id) {
  return {
    type: UPDATE_LANG,
    payload: id
  };
};

// 获取内容语言列表
export var getClangList = function getClangList() {
  return function (dispatch) {
    return import('@client/common/models/api-cf/configCf').then(function (_ref3) {
      var configCfModel = _ref3.configCfModel;
      return configCfModel.fetchLanguageSetting();
    }).then(function (clangList) {
      var priorityClangOrder = [1046, 1034, 1054, 1057, 1066, 1081, 1033];
      var newClang = new Array(7);
      var rest = [];
      for (var i = 0; i < clangList.length; i += 1) {
        var curClang = clangList[i];
        var priorityIndex = priorityClangOrder.indexOf(curClang.lcid);
        if (priorityIndex !== -1) {
          newClang[priorityIndex] = curClang;
        } else {
          rest.push(curClang);
        }
      }
      newClang = newClang.filter(function (cl) {
        return !!cl;
      });
      var result = [].concat(_toConsumableArray(newClang), rest);
      dispatch({
        type: GET_CLANG_LIST,
        payload: result
      });
      return result;
    });
  };
};

// 更新内容语言ID
export var updateClang = function updateClang(id) {
  return {
    type: UPDATE_CLANG,
    payload: id
  };
};
export var updateUserInfo = function updateUserInfo(info) {
  return {
    type: UPDATE_USER_INFO,
    payload: info
  };
};
export function getDecorationInfoList(decorationInfoList) {
  return {
    type: GET_DECORATION_INFO_LIST,
    payload: decorationInfoList
  };
}