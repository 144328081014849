import { Logger, setLogLevel, getLogLevel, LOG_LEVEL, setDebug } from '@nimo-fed/util';
var isDebug = DOMAINS.logging;
setDebug(isDebug);
export default new Logger({
  prefix: 'nm'
});
export { Logger, setLogLevel };
if (isDebug) {
  if (getLogLevel() >= LOG_LEVEL.OFF) {
    // 非生产环境，如果没打开日志，则强制打开开关
    setLogLevel(LOG_LEVEL.ALL);
  }
}