import auth from '@client/common/services/auth';
import { getSentry } from '@client/common/utils/getSentry';
getSentry().then(function (Sentry) {
  if (Sentry) {
    var info = auth.getPureCache();
    var handleInfo = function handleInfo(userInfo) {
      var udbUserId = userInfo.udbUserId;
      Sentry.setTag('udbUserId', udbUserId > 0 ? udbUserId : 0);
    };
    if (info) {
      handleInfo(info);
    } else {
      auth.getUserInfo().then(handleInfo);
    }
  }
});