export var StaticUrlParams = ['_pop', '_udbid', '_udbtoken', '_udbver'];
export default function (paramName) {
  var url = window.location.href.replace(location.hash, '').replace('#', ''); // remove hash
  var urlArr = url.split('?');
  var paramReg = paramName;
  if (paramName instanceof Array) {
    paramReg = paramName.join('|');
  }
  var re = new RegExp("(".concat(paramReg, ")=(\\w|.)+"));
  var params = urlArr[1] ? urlArr[1].split('&').filter(function (param) {
    return !re.test(param);
  }).join('&') : '';
  return "".concat(urlArr[0]).concat(params ? "?".concat(params) : '');
}
export function removeUrlParamsReturnOnlyQuery(paramName) {
  var url = window.location.href.replace(location.hash, '').replace('#', ''); // remove hash
  var urlArr = url.split('?');
  var paramReg = paramName;
  if (paramName instanceof Array) {
    paramReg = paramName.join('|');
  }
  var re = new RegExp("(".concat(paramReg, ")=(\\w|.)+"));
  var params = urlArr[1] ? urlArr[1].split('&').filter(function (param) {
    return !re.test(param);
  }).join('&') : '';
  return "".concat(params ? "?".concat(params) : '');
}
export function removeNewUrl(url, paramName) {
  var urlArr = url.split('?');
  var paramReg = paramName;
  if (paramName instanceof Array) {
    paramReg = paramName.join('|');
  }
  var re = new RegExp("(".concat(paramReg, ")=(\\w|.)+"));
  var params = urlArr[1] ? urlArr[1].split('&').filter(function (param) {
    return !re.test(param);
  }).join('&') : '';
  return "".concat(urlArr[0]).concat(params ? "?".concat(params) : '');
}