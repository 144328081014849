import ua from '@client/common/utils/ua.js';
import { setCanonicalUrl, setMediaLink, setHreflangInHead } from './recordMeta';

/**
 * 前提：robots.txt Disallow 的路由忽略处理
 * 给每个页面添加动态meta 
 * @param {boolean} props.isSingleLang  - 文章是否是单一语言
 * @param {boolean} props.urlOnlyPc  - 路由只有www，没有m
 * @param {boolean} props.urlOnlyWap  - 路由只有m，没有www
 */
export var addHreflangMeta = function addHreflangMeta() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
      isSingleLang: false,
      urlOnlyWap: false,
      urlOnlyPc: false
    },
    isSingleLang = _ref.isSingleLang,
    urlOnlyWap = _ref.urlOnlyWap,
    urlOnlyPc = _ref.urlOnlyPc;
  if (!urlOnlyWap) {
    setCanonicalUrl();
  }
  if (!isSingleLang) {
    setHreflangInHead();
  }
  if (!urlOnlyPc && !urlOnlyWap && ua.isDesktop) {
    setMediaLink();
  }
};
var commonHandler = function commonHandler(options) {
  try {
    addHreflangMeta(options);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('set hreflang meta fail: ', e);
  }
};

/**
 * 当url变化时执行
 * 1. 已添加进单页和多页入口；
 * 2. 暂时没有在 /mkt /fragments 入口添加
 */
export default commonHandler;