import domains from '@client/common/services/domains';
import webProd, { sphdExp as webProdSphdExp } from './web.prod';
import webTest, { sphdExp as webTestSphdExp } from './web.test';
import wapProd, { sphdExp as wapProdSphdExp } from './wap.prod';
import wapTest, { sphdExp as wapTestSphdExp } from './wap.test';
import ayyuidProd from './ayyuid.prod';
import ayyuidTest from './ayyuid.test';
var isDebug = domains.isDebug === 'true';
function getSphdExp(isWap) {
  if (!isWap) {
    return isDebug ? webTestSphdExp : webProdSphdExp;
  }
  return isDebug ? wapTestSphdExp : wapProdSphdExp;
}
;

/**
 * sphd 白名单
 */
function checkInSphdWhiteList(uid) {
  var whiteList = isDebug ? ayyuidTest : ayyuidProd;
  return uid && whiteList.indexOf(uid) > -1;
}
export { getSphdExp, checkInSphdWhiteList };
export default function getAbConfig(isWap) {
  if (!isWap) {
    return isDebug ? webTest : webProd;
  }
  return isDebug ? wapTest : wapProd;
}
;