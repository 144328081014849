import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import auth from '@client/common/services/auth';
export var INIT_ROOM_INFO = 'init_room_info';
export var INIT_ROOM_GIFT_LIST = 'init_room_gift_list';
export var UPDATE_ACCOUNT_BALANCE = 'update_account_balance';
export var PLAYER_PAUSE = 'player_pause';
export var ANCHOR_ONLINE = 'anchor_online';
export var UPDATE_USER_INFO = 'update_user_info';
export var STREAM_UPDATE = 'stream_update';
export var UPDATE_CURR_FANS_BARRAGE = 'update_curr_fans_barrage';
export var UPDATE_GIFT_PACK_NEW = 'update_gift_pack_new';
export var UPDATE_WEAR_BADGE_INFO = 'update_wear_badge_info';
export var INIT_ROOM_ACTIVITY_LIST = 'init_room_activity_list';
export var ANCHOR_PK_ON = 'anchor_pk_on';
export var UPDATE_LIVE_ROOM_VIEW_COUNT = 'update_live_room_view_count';
export var UPDATE_ANCHOR_FOLLOW_COUNT = 'update_anchor_follow_count';
export var INIT_FACIALS_LIST = 'init_facials_list';
export var INIT_SUBSCRIBE_STATUS = 'init_subscribe_status';
export var SET_MEETING_EMOJI_LIST = 'SET_MEETING_EMOJI_LIST';
export var commonMergeTypes = [INIT_ROOM_GIFT_LIST, UPDATE_ACCOUNT_BALANCE, PLAYER_PAUSE, ANCHOR_ONLINE, UPDATE_USER_INFO, STREAM_UPDATE, UPDATE_CURR_FANS_BARRAGE, UPDATE_GIFT_PACK_NEW, UPDATE_WEAR_BADGE_INFO, INIT_ROOM_ACTIVITY_LIST, ANCHOR_PK_ON, UPDATE_LIVE_ROOM_VIEW_COUNT, UPDATE_ANCHOR_FOLLOW_COUNT, INIT_FACIALS_LIST, INIT_SUBSCRIBE_STATUS, SET_MEETING_EMOJI_LIST];
export function initRoomInfo(roomInfo) {
  return _objectSpread({
    type: INIT_ROOM_INFO
  }, roomInfo);
}
export function initRoomGiftList(giftList) {
  return {
    type: INIT_ROOM_GIFT_LIST,
    giftList: giftList,
    giftListLoaded: true
  };
}
export function updateAccountBalance(accountBalance) {
  return {
    type: UPDATE_ACCOUNT_BALANCE,
    accountBalance: accountBalance
  };
}
export function playerPause(playerState) {
  return {
    type: PLAYER_PAUSE,
    playerState: playerState
  };
}
export function anchorOnline(isOnline) {
  return {
    type: ANCHOR_ONLINE,
    isOnline: isOnline
  };
}
export var updateUserInfo = function updateUserInfo() {
  return function (dispatch) {
    return auth.getUserInfo().then(function (userInfo) {
      dispatch({
        type: UPDATE_USER_INFO,
        userInfo: userInfo
      });
      return userInfo;
    });
  };
};
export function streamUpdate(stream, streamId) {
  return {
    type: STREAM_UPDATE,
    stream: _defineProperty({}, streamId, stream)
  };
}
export function updateCurrFansBarrage(barrage) {
  return {
    type: UPDATE_CURR_FANS_BARRAGE,
    currFansBarrage: barrage
  };
}
export function updateGiftPackNew(giftPackNew) {
  return {
    type: UPDATE_GIFT_PACK_NEW,
    giftPackNew: giftPackNew
  };
}
export function updateWearBadgeInfo(wearBadgeInfo) {
  return {
    type: UPDATE_WEAR_BADGE_INFO,
    wearBadgeInfo: wearBadgeInfo
  };
}
export function initRoomActivityList(activityList) {
  var normalActivityList = activityList.normalActivityList,
    specialActivity = activityList.specialActivity;
  return {
    type: INIT_ROOM_ACTIVITY_LIST,
    normalActivityList: normalActivityList,
    specialActivity: specialActivity
  };
}
export function updateAnchorPkOn(anchorPKOn) {
  return {
    type: ANCHOR_PK_ON,
    anchorPKOn: anchorPKOn,
    anchorPKOnFetched: true
  };
}
export function updateLiveRoomViewCount(viewCount) {
  return {
    type: UPDATE_LIVE_ROOM_VIEW_COUNT,
    viewCount: viewCount
  };
}
export function updateAnchorFollowCount(followCount) {
  return {
    type: UPDATE_ANCHOR_FOLLOW_COUNT,
    followCount: followCount
  };
}
export function initFacialsList(facialsList) {
  return {
    type: INIT_FACIALS_LIST,
    facialsList: facialsList
  };
}
export function initVipSubscribeStatus(subscribeStatus) {
  return {
    type: INIT_SUBSCRIBE_STATUS,
    subscribeStatus: subscribeStatus
  };
}