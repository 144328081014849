export { default } from '@client/common/services/report';
// import {isInNimoApp} from './jsBridge';

// const pro = getGlobalReportPro() || (isInNimoApp() ? DOMAINS.pasnimoactivity : DOMAINS.pasnimowap);

// const nimoReport = new Report({
//   pro,
// });

// nimoReport.init();

// export default nimoReport;