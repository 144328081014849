var _window;
import Report, { getGlobalReportPro } from '@client/common/lib/dataReport';
import ua from '@client/common/utils/ua';
var isInNimoApp = ua.isInNimoApp,
  isWap = ua.isWap;
var mapReportTypeToPro = {
  activity: DOMAINS.pasnimoactivity,
  desktop: DOMAINS.pasnimoweb,
  mobile: DOMAINS.pasnimowap
};

// getGlobalReportPro: url _rep 参数控制上报项目
// window?.G_REPORT_TYPE: 业务代码控制上报项目 变量设置需要比 report 初始化早
// 权重 ==> url _rep 参数 > window?.G_REPORT_TYPE > ua 判断
var pro = getGlobalReportPro() || mapReportTypeToPro[(_window = window) === null || _window === void 0 ? void 0 : _window.G_REPORT_TYPE] || '';
if (!pro) {
  if (isInNimoApp) {
    pro = DOMAINS.pasnimoactivity;
  } else if (isWap) {
    pro = DOMAINS.pasnimowap;
  } else {
    pro = DOMAINS.pasnimoweb;
  }
}

// 仅初始化 report 实例，默认不上报 startup、心跳等日活相关埋点
// 目前在登录页面会使用这个实例
var nimoReport = new Report({
  pro: pro
});
export default nimoReport;