import { loadLangPkg, normalizeLangRegistry } from '@client/common/lang';
export var SET_MEETING_INFO = 'set_meeting_info';
export var SET_CONTENT_COUNTRY_LIST = 'set_content_country_List';
export var SET_CONTENT_COUNTRY = 'set_content_country';
export var SET_ROYAL_LEVEL_CONFIG = 'set_royal_level_config';
export var SET_POPOUT_STATE = 'set_popout_state';
export var SET_SELF_ROOM_LINK = 'set_self_room_link';
export var UPDATE_LANGPKG = 'base_update_langpkg';
export function setMeetingInfo(meetingInfo) {
  return {
    type: SET_MEETING_INFO,
    meetingInfo: meetingInfo
  };
}

/**
 * 获取内容国家列表
 */
export function setCCountryList(cCountryList) {
  return {
    type: SET_CONTENT_COUNTRY_LIST,
    cCountryList: cCountryList
  };
}

/**
 * 获取内容国家列表
 */
export function setCCountry(cCountry) {
  return {
    type: SET_CONTENT_COUNTRY,
    cCountry: cCountry
  };
}

/**
 * 获取贵族等级配置
 */
export function setRoyalLevelConfig(royalLevelConfig) {
  return {
    type: SET_ROYAL_LEVEL_CONFIG,
    royalLevelConfig: royalLevelConfig
  };
}
export function setSelfRoomLink(url) {
  return {
    type: SET_SELF_ROOM_LINK,
    selfRoomLink: url
  };
}
export function setPopoutState(key, value) {
  return {
    type: SET_POPOUT_STATE,
    key: key,
    value: value
  };
}

/**
 * 统一更新多语言
 * @param {any} registry
 * @param {number|string} [overrideLang]
 * @returns
 */
export function updateLangPkg(registry, overrideLang) {
  return function (dispatch) {
    var langRegistry = normalizeLangRegistry(registry);
    if (!langRegistry) {
      return Promise.reject(Error('is not langRegistry'));
    }
    return loadLangPkg(langRegistry, overrideLang).then(function (langPkg) {
      if (langPkg) {
        dispatch({
          type: UPDATE_LANGPKG,
          key: langRegistry.namespace,
          value: langPkg
        });
      }
      return langPkg;
    });
  };
}