export var sphdExp = '907.1178';
export default [[{
  params: {
    groupId: '821',
    g_trafficAlloc: ['0~9999'],
    salt: '17e14e10',
    v_trafficAlloc: ['0~4999']
  },
  oexp: '907.1177'
}, {
  params: {
    groupId: '821',
    g_trafficAlloc: ['0~9999'],
    salt: '17e14e10',
    v_trafficAlloc: ['5000~9999']
  },
  oexp: sphdExp
}]];