import ua from '@client/common/utils/ua';
var supported;
export default function supportInstallPrompt() {
  if (supported != null) return supported;
  supported = !ua.isInNimoApp && !ua.isInNimoPc && 'serviceWorker' in navigator && 'onbeforeinstallprompt' in window && ua.parser.satisfies({
    windows: {
      chrome: '>=70',
      edge: '>=70'
    },
    linux: {
      chrome: '>=70',
      edge: '>=70'
    },
    macos: {
      chrome: '>=73',
      edge: '>=73'
    },
    'Chrome OS': {
      chrome: '>=67'
    },
    android: {
      chrome: '>=42',
      edge: '>=70'
    }
  });
  return supported;
}