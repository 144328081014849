import _extends from "@babel/runtime/helpers/extends";
import "core-js/modules/es6.array.sort.js";
/* eslint-disable prefer-destructuring */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable new-cap */
/* eslint-disable prefer-rest-params */
/* eslint-disable prefer-spread */
/* eslint-disable no-unused-expressions */
/* eslint-disable import/first */

export * from './cookieFn';
import ua from '@client/common/utils/ua.js';
import Logger from '@client/common/utils/logger.js';
import history from '@client/common/services/react-history';
import { twoEndOrigin } from '@client/common/services/url';
import { setRoomEnterInfo } from '@client/common/services/setRoomEnterInfo';
import EClientType from '@client/jce/Nimo/EClientType';
import { handlers } from '@client/common/services/handlers';
import { parseURL, addUrlParams } from '@nimo-fed/util';
import { inIframe } from '@client/common/utils/inIframe';
import { getParentHost, getParentUrl } from './parentUrl';
export { inIframe, getParentHost, getParentUrl, parseURL };
var _log = Logger.log;
export var navigateMainDomain = function navigateMainDomain(pathname) {
  var delay = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 200;
  if (window.isHomeSPA) {
    history.push(pathname);
  } else {
    var navi = function navi() {
      location.href = "//".concat(DOMAINS.maindomain).concat(pathname);
    };
    if (typeof delay === 'number' && delay > 0) {
      setTimeout(navi, delay);
    } else {
      navi();
    }
  }
};
export var checkRoomIsMatchRoom = function checkRoomIsMatchRoom(roomSort) {
  return roomSort && roomSort === 1;
};

// 随机范围数
export var rRangeNum = function rRangeNum() {
  var min = 0;
  var max = 0;
  var arg = Array.prototype.slice.apply(arguments);
  var arglen = arg.length;
  if (arglen === 0) {
    max = 1;
  }
  if (arglen === 1) {
    max = arg[0];
  }
  if (arglen === 2) {
    min = arg[0];
    max = arg[1];
  }
  min *= 1;
  max *= 1;
  var Range = max - min + 1;
  var Rand = Math.random();
  var num = min + Math.floor(Rand * Range); // 向下取整
  return num;
};

// 测试范围
// for (let index = 0; index < 100000; index++) {
//   let num = rRangeNum(1,100)
//   if (num === 1) {
//     console.log('出现',num);
//   }
//   if (num === 0) {
//     console.log('出现',num);
//   }
//   if (num === 100) {
//     console.log('出现',num);
//   }
//     if (num === 101) {
//     console.log('出现',num);
//   }
// }

// 测试概率
// var a = [];
// var b= [];
// for (let index = 0; index < 100000; index++) {
//   let num = rRangeNum(1,100)
//   if (num <= 50) {
//     a.push(1)
//   }else{
//     b.push(2)
//   }
// }
// console.log('a组长度',a.length);
// a = a.length;
// console.log('b组长度',b.length);
// b= b.length
// console.log('误差率',Math.abs(a-b)/(a+b)*100+'%');

// 写入主播投放直播间链接的roomId
// https://shimo.im/docs/1P6tzeYXeNYl7Ur2
export var setAnchorLinkRoomId = function setAnchorLinkRoomId() {
  var _curUrlObj$params;
  var anchorRoomId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  var curUrlObj = parseURL(document.URL);
  var activityParams = curUrlObj === null || curUrlObj === void 0 || (_curUrlObj$params = curUrlObj.params) === null || _curUrlObj$params === void 0 ? void 0 : _curUrlObj$params.activity;
  if (activityParams && +activityParams === 2) {
    var _window$G_roomBaseInf;
    var roomId = anchorRoomId || ((_window$G_roomBaseInf = window.G_roomBaseInfo) === null || _window$G_roomBaseInf === void 0 ? void 0 : _window$G_roomBaseInf.roomId); // FIXME: 单页切换直播间时会取到原来直播间的信息
    if (roomId) {
      var cookieValue = "ss_source_anchor_link_roomId=".concat(encodeURI(roomId), ";domain=.nimo.tv;path=/");
      document.cookie = cookieValue;
    }
  }
};

// 获取指定时区的Date对象，默认是中国时区
export var getTimeZoneDate = function getTimeZoneDate() {
  var offset = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : -8;
  var date = new Date();
  var utc = date.getTime() - date.getTimezoneOffset() * 60000;
  var rst = new Date(utc + 3600000 * offset);
  return rst;
};

// 判断进入直播间是否使用别名路径
export var roomEnterURL = function roomEnterURL(param) {
  var _ref = param || {},
    alias = _ref.alias,
    _extraParams = _ref._extraParams;
  var _ref2 = param || {},
    alise = _ref2.alise;
  var roomId = getRoomId(param);
  alise = alise || alias;
  var url = alise ? "/".concat(alise) : "/live/".concat(roomId);

  //
  if (_extraParams !== undefined) {
    url = addUrlParams(url, _extraParams);
  }
  return url;
};

// 判断进入个人页是否使用别名路径
export var userPageEnterURL = function userPageEnterURL(param) {
  var _ref3 = param || {},
    alias = _ref3.alias,
    _extraParams = _ref3._extraParams;
  var _ref4 = param || {},
    alise = _ref4.alise;
  var roomId = getRoomId(param);
  alise = alise || alias;
  var url = alise ? "/user/".concat(alise) : "/user/".concat(roomId);
  if (_extraParams !== undefined) {
    url = addUrlParams(url, _extraParams);
  }
  return url;
};
export var roomEnterURLWithDomain = function roomEnterURLWithDomain(param) {
  var path = roomEnterURL(param);
  return "".concat(twoEndOrigin).concat(path);
};

// 进入直播间的公共入口操作
/** 
 * pc主站
 * data.roomId => 房间ID
 * 
 data._from => 页面入口字段，遵循[页面名称][模块名称][组件名称]规则，埋点上报和播放器链路上报会使用过，详情如下
 header-history : 头部历史列表
 header-follow : 头部关注列表
 home-banner : 首页 - banner
 home-match : 首页 - 赛事
 home-rec : 首页 - 推荐
 home-hot : 首页 - HOT栏目
 home-team : 首页 - 明星战队
 home-column : 首页 - 推荐栏目
 home-player-enter-room-hover : 首页 - 播放器 - 进入直播间悬浮按钮
 home-player-enter-room-btn : 首页 - 播放器 - 进入直播间常驻按钮
 room-team-navTags : 战队直播间 - 导航按钮
 player-rec : 播放器 - 推荐列表
 lives : 全部直播页 - 卡片入口
 match : 全部赛事页 - 比赛中按钮入口
 room-rec : 普通直播间 - 推荐卡片入口
 search-anchor : 搜索 - 主播卡片入口 
 search-live : 搜索 - 直播卡片入口 
 my-subscribe : 个人中心 - 我的关注列表 - 卡片入口
 game-live : 游戏列表 - 卡片入口
 list-live : 分类列表 - 卡片入口
 sidebar-follow : 侧边栏关注列表
 video-metadata : 视频页主播信息昵称
 AuthorAvatar : 视频页主播信息头像，旧命名不能更改
 room-mc-together : 主播连麦
 user-header-live : 个人页 live 图标
 user-header-replay : 个人页 replay 图标
 min-player-expend-btn : mini 播放器 - 回到直播间按钮
 ranks-list : 排行榜 - 跳转直播间
 player-load-again : 播放器 - 再次拉流（卡顿、切换分辨率、刷新、播放）
 giftbanner: 世界横幅
 clan-traffic: 个人中心-公会管理-流量分析页
 matches-anchor-recommend: 赛事中心-推荐主播
 matches-live-list: 赛事中心-栏目卡片
 other_click: 其它未知情况
 check_room : 主播查房

 * M站
 * data.roomId => 房间ID
 * 
 * 如果需要app跳转直播间，需要传入anchorId字段和jumpToLiveRoom方法
 
 * add方法: 新增业务通过该方法进行扩展，回传两个参数data和index
 */
export var roomEnter = function roomEnter(data) {
  var opt = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  roomEnter.queue.forEach(function (v, k) {
    try {
      v(data, k);
    } catch (error) {
      console.error(error);
    }
  });
  var isInNimoApp = ua.isInNimoApp,
    isInNimoPc = ua.isInNimoPc;
  _log('触发roomEnter -> ', {
    isInNimoApp: isInNimoApp,
    data: data,
    opt: opt
  });
  if (!isInNimoApp && !isInNimoPc) {
    setRoomEnterInfo({
      from: (data === null || data === void 0 ? void 0 : data._from) || 'other_click',
      traceData: (data === null || data === void 0 ? void 0 : data.traceData) || ''
    });
  }
  if (isInNimoApp) {
    var anchorId = data === null || data === void 0 ? void 0 : data.anchorId;
    var roomId = data === null || data === void 0 ? void 0 : data.roomId;
    if (roomId && anchorId && opt !== null && opt !== void 0 && opt.jumpToLiveRoom) {
      opt === null || opt === void 0 || opt.jumpToLiveRoom({
        anchorId: anchorId,
        roomId: roomId
      });
    }
  } else if (isInNimoPc && handlers.openBrowser) {
    handlers.openBrowser("".concat(twoEndOrigin).concat(roomEnterURL(data)));
  } else if (opt !== null && opt !== void 0 && opt.openNewTab || window._IS_POPOUT_) {
    // opt 中的 openNewTab 表明在新窗口打开
    window.open("".concat(twoEndOrigin).concat(roomEnterURL(data)));
  } else if (window.isHomeSPA) {
    // TODO(zhongweibin): 改用其他方式保存时间戳
    // 设置需要在 history.push 之前，否则
    window.__clickRoomFrom = (data === null || data === void 0 ? void 0 : data._from) || 'other_click';
    window.__clickRoomTs = getCurrentTs();
    history.push(roomEnterURL(data));
  } else {
    setTimeout(function () {
      location.href = "".concat(twoEndOrigin).concat(roomEnterURL(data));
    }, 200);
  }
};
roomEnter.queue = [];
roomEnter.add = function (fn) {
  roomEnter.queue.push(fn);
};

/**
 * 打开个人页
 * @param {*} dataSource
 * @param {*} props
 * @returns
 */
export var onOpenUserInfo = function onOpenUserInfo(dataSource, opts) {
  if (ua.isInNimoApp) {
    var _opts$showUserInfoDia;
    opts === null || opts === void 0 || (_opts$showUserInfoDia = opts.showUserInfoDialog) === null || _opts$showUserInfoDia === void 0 || _opts$showUserInfoDia.call(opts, {
      udbUserId: dataSource === null || dataSource === void 0 ? void 0 : dataSource.udbUserId,
      from: opts === null || opts === void 0 ? void 0 : opts.from
    });
    return;
  }
  var userId = dataSource.userId,
    roomId = dataSource.roomId;
  var url = "https://".concat(DOMAINS.maindomain, "/user/").concat(userId || roomId);
  if (opts !== null && opts !== void 0 && opts.openNewTab) {
    window.open(url, '_blank');
  } else if (window.isHomeSPA) {
    history.push("/user/".concat(userId || roomId));
  } else {
    window.open(url, '_self');
  }
};

// 获取房间ID，兼容接口
export var getRoomId = function getRoomId(data) {
  return (data === null || data === void 0 ? void 0 : data.roomId) || (data === null || data === void 0 ? void 0 : data.roomID) || (data === null || data === void 0 ? void 0 : data.roomid) || (data === null || data === void 0 ? void 0 : data.id) || (data === null || data === void 0 ? void 0 : data.userId);
};

/**
 * 限制仅模板系统的正式服、测试服文章才能 iframe 嵌入
 * 兼容新老地址
 */
export var isArticleOrigin = function isArticleOrigin(origin) {
  return (DOMAINS.isDebug === 'true' ? ['https://article.nimo.tv', 'https://article-test.nimo.tv', 'https://web-article.nimostatic.tv', 'https://web-article-test.nimostatic.tv'] : ['https://article.nimo.tv', 'https://web-article.nimostatic.tv']).indexOf(origin) > -1;
};
export var isS3StaticOrigin = function isS3StaticOrigin(origin) {
  return ['https://s3-static.nimo.tv', 'https://s3-pre-static.nimo.tv'].includes(origin);
};

// see also: safeOrigins.js
export var isNimoWebOrigin = function isNimoWebOrigin(origin) {
  return (DOMAINS.isDebug === 'true' ? ['https://www.nimo.tv', 'https://www-test.nimo.tv', 'https://www-dev.nimo.tv', 'https://m.nimo.tv', 'https://m-test.nimo.tv', 'https://m-dev.nimo.tv', 'https://dashboard.nimo.tv', 'https://dashboard-test.nimo.tv', 'https://dashboard-dev.nimo.tv', 'https://act.nimo.tv', 'https://act-test.nimo.tv', 'https://act-local.nimo.tv', 'https://game.nimo.tv', 'https://game-test.nimo.tv', "https://".concat(DOMAINS.maindomain), // NOTE: 解决本地开发域名不一致，导致桥不通问题
  "https://".concat(DOMAINS.wapdomain), "https://".concat(DOMAINS.dashboarddomain), "https://".concat(DOMAINS.actdomain), "https://".concat(DOMAINS.gamedomain)] : ['https://www.nimo.tv', 'https://m.nimo.tv', 'https://dashboard.nimo.tv', 'https://act.nimo.tv', 'https://game.nimo.tv', "https://".concat(DOMAINS.maindomain), // NOTE: 解决本地开发域名不一致，导致桥不通问题
  "https://".concat(DOMAINS.wapdomain), "https://".concat(DOMAINS.dashboarddomain), "https://".concat(DOMAINS.actdomain), "https://".concat(DOMAINS.gamedomain)]).indexOf(origin) > -1;
};

/**
 * 人气转换单位显示
 */
export var setViewNumShow = function setViewNumShow(num) {
  return num > 1000 ? "".concat(Math.round(num / 100) / 10, "k") : num;
};

// 滚动条-回到顶部
export var scrollTop = function scrollTop() {
  var _window;
  if ((_window = window) !== null && _window !== void 0 && _window.scroll) {
    window.scroll(0, 0);
  } else {
    return false;
  }
};

// scrollY
export var getScrollY = function getScrollY() {
  return window.pageYOffset !== undefined ? window.pageYOffset : document.body.scrollTop || document.documentElement.scrollTop;
};

// 检查是否支持webp
export var isWebp = function isWebp() {
  var cva = document.createElement('canvas');
  var rst = !![].map && cva.toDataURL('image/webp').indexOf('data:image/webp') === 0;
  cva = null;
  return rst;
};

/**
 * 根据 url 判断当前页面是否是个人中心页面
 */
export var isProfilePage = function isProfilePage() {
  var urlObj = parseURL();
  var _ref5 = urlObj || {},
    _ref5$pathArray = _ref5.pathArray,
    pathArray = _ref5$pathArray === void 0 ? [] : _ref5$pathArray;
  return pathArray[0] === 'i';
};

/**
 * 步骤管理器
 */
export var Step = function Step(opt) {
  var _config = {
    list: [],
    pointer: 0,
    loopNum: 0,
    reverse: false,
    loop: false,
    onEnd: false,
    end: false,
    interval: false,
    onStart: false,
    runDirec: 1
  };
  var step = function step(opts) {
    this.next = this._next;
    this.prev = this._prev;
    this.runDirec = 1;
    _extends(this, _config, opts);
  };

  /** 添加 */
  step.prototype.add = function () {
    var cur;
    var list = this.list;
    var arg = Array.prototype.slice.call(arguments);
    for (var i = 0, len = arg.length; i < len; i += 1) {
      cur = arg[i];
      cur.index = list.length;
      if (Array.isArray(cur)) {
        list = list.concat(cur);
      } else {
        list.push(cur);
      }
    }
    this.list = list;
  };

  /** 删除 */
  step.prototype.remove = function (num) {
    if (num !== undefined && num !== null && typeof num === 'number') {
      this.list.splice(num, 1);
    } else {
      this.list = [];
    }
  };

  /** 下一步 */
  step.prototype._next = function () {
    this.runDirec = 1;
    this.pointer += 1;
    this.stepRang();
    return this.run.apply(this, Array.prototype.slice.apply(arguments));
  };

  /** 上一步 */
  step.prototype._prev = function () {
    this.runDirec = -1;
    this.pointer -= 1;
    this.stepRang();
    return this.run.apply(this, Array.prototype.slice.apply(arguments));
  };

  /** 范围矫正 */
  step.prototype.stepRang = function () {
    var pt = this.pointer;
    var list = this.list;
    var reverse = this.reverse;
    var loop = this.loop;
    var listLen = list.length;
    var rst = false;
    var ptr = pt >= listLen;
    var ptl = pt < 0;
    if (ptr || ptl) {
      this.loopNum += 1;
      rst = true;
      if (loop) {
        this.pointer = 0;
      } else if (ptr) {
        this.end = true;
      }
      if (ptr) {
        this.onEnd ? this.onEnd() : '';
      }
      if (ptl) {
        this.onStart ? this.onStart() : '';
      }
      if (reverse) {
        if (this.runDirec > 0) {
          this.pointer = listLen - 2;
          this.next = this._prev;
          this.prev = this._next;
        } else {
          this.pointer = 1;
          this.next = this._next;
          this.prev = this._prev;
        }
      }
    }
    return rst;
  };

  /** 执行当前步骤 */
  step.prototype.cur = function () {
    var pt = this.pointer;
    var list = this.list;
    var listLen = list.length;
    var ptr = pt >= listLen;
    var ptl = pt < 0;
    if (ptr) {
      pt = listLen - 1;
    }
    if (ptl) {
      pt = 0;
    }
    this.pointer = pt;
    return this.run.apply(this, Array.prototype.slice.apply(arguments));
  };

  /** 步骤执行 */
  step.prototype.run = function () {
    var arg = Array.prototype.slice.apply(arguments);
    var _return;
    var list = this.list;
    var delay = this.delay;
    var that = this;
    if (!list || list.length <= 0 || this.end) {
      return null;
    }
    var curRun = list[this.pointer];
    if (curRun) {
      if (typeof curRun === 'function') {
        if (delay) {
          setTimeout(function () {
            _return = curRun.apply(that, arg);
          }, delay + this.delayDval * this.pointer);
        } else {
          _return = curRun.apply(this, arg);
        }
      } else {
        _return = curRun;
      }
      return _return;
    } else {
      return null;
    }
  };
  /** 移动指针 */
  step.prototype.move = function (num) {
    if (num !== undefined && num !== null && typeof num === 'number') {
      this.pointer = num;
    }
  };
  return new step(opt);
};
export function isDate18orMoreYearsOld(timestamp) {
  var d = new Date(timestamp);
  var year = d.getFullYear();
  d.setHours(0, 0, 0, 0);
  d.setFullYear(year + 18);
  return d <= new Date();
}
export var isInNimoApp = function isInNimoApp() {
  return /^.*(nimo).*$/.test(navigator.userAgent);
};
export var isInNimoTv = function isInNimoTv() {
  return /^.*(nimotv).*$/.test(navigator.userAgent);
};
export var isInNimoStreamer = function isInNimoStreamer() {
  return /^.*(nimostreamer).*$/.test(navigator.userAgent);
};

/* 裁剪图片-简版
 *
 */
export var clipImage = function clipImage() {
  var opt = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var src = opt === null || opt === void 0 ? void 0 : opt.src;
  if (!src) {
    return false;
  }
  _log('原图 大小 -> ', "".concat(Math.round(src.length / 1024 * 0.77), "KB"));
  var width = opt === null || opt === void 0 ? void 0 : opt.width;
  var height = opt === null || opt === void 0 ? void 0 : opt.height;
  var zip = (opt === null || opt === void 0 ? void 0 : opt.zip) || false;
  var type = (opt === null || opt === void 0 ? void 0 : opt.imgType) || 'jpeg';
  var size = (opt === null || opt === void 0 ? void 0 : opt.size) || false;
  var canvas = document.createElement('canvas');
  var context = canvas.getContext('2d');
  var img = new Image();
  img.src = src;
  var _clear = function _clear() {
    img = null;
    canvas = null;
    src = null;
  };
  var async = new Promise(function (yes, no) {
    img.onload = function () {
      var imgW = img.naturalWidth;
      var imgH = img.naturalHeight;
      var _width;
      var _height;
      if (size) {
        zip = 0.92;
        // var imgArea = imgW*imgH;
        // var _area = imgArea / (src.length*0.77) * (size*1024)
        // var {x:_width} = keepRatio( imgW,imgArea ,0,_area);
        // var {x:_height} = keepRatio( imgH, imgArea ,0,_area);

        // var zip = (size*1024) / (src.length*0.77);
        // _width = imgW;
        // _height = imgH;
        _width = keepRatio(imgW, src.length * 0.77, 0, size * 1024).x;
        _height = keepRatio(imgW, imgH, _width, 0).y;
      } else {
        var ratio = keepRatio(imgW, imgH, width, height);
        _width = ratio.x;
        _height = ratio.y;
      }
      canvas.width = _width;
      canvas.height = _height;
      context.drawImage(img, 0, 0, _width, _height);
      var _src = canvas.toDataURL("image/".concat(type), zip);
      _log('裁剪后 大小 -> ', "".concat(Math.round(_src.length / 1024 * 0.77), "KB"));
      _log('裁剪后 传输 大小 -> ', "".concat(Math.round(_src.length / 1024), "KB"));
      yes(_src);
      _clear();
    };
    img.onerror = function () {
      no();
      _clear();
    };
  });
  return async;
};

// 保持比例
export var keepRatio = function keepRatio(x1, y1) {
  var x2 = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  var y2 = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
  var isFloor = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : true;
  var _x2 = !x2 ? x1 * y2 / y1 : x2;
  var _y2 = !y2 ? x2 * y1 / x1 : y2;
  _x2 = isFloor ? Math.floor(_x2) : _x2;
  _y2 = isFloor ? Math.floor(_y2) : _y2;
  return {
    x: _x2,
    y: _y2
  };
};

// window.open 拦截兼容 - 主要兼容火狐的广告拦截
export var windowOpen = function windowOpen() {
  var args = Array.from(arguments);
  var _win = window.open.apply(undefined, args);
  _win.location.href = decodeURIComponent(args[0]);
  return _win;
};

/**
 * 将数字转化为序数词
 * @param {number} num
 */
export var numberToOrdinal = function numberToOrdinal(num) {
  if (!num || typeof num !== 'number') {
    return null;
  }
  var singleDigit = num % 10;
  var result = 'th';
  switch (singleDigit) {
    case 1:
      result = 'st';
      break;
    case 2:
      result = 'nd';
      break;
    case 3:
      result = 'rd';
      break;
    default:
      break;
  }
  return result;
};

/* 
获取当前referrer 网站来源 请求来源
返回值:
type(类型)(int) 0:直达 1:其他(未知)网站 2:搜索引擎 3:社交媒体
name(名字)(string) 和third_website对应
注意：在安卓4.4以下，上一个页面进入后，再本页刷新，document.referrer会为空，如果要做持续化存储需要自己做，比如用sessionStorage。
 */
export var getReferrer = function getReferrer() {
  // 配置信息-第三方网站信息,key为域名的最短关键字,特殊场景的key请用_xxx_格式命名
  // type 0:直达 1:其他(未知)网站 2:搜索引擎 3:社交媒体
  var third_website = {
    _direct_: {
      type: '0'
    },
    nimo: {
      type: '0'
    },
    _other_: {
      type: '1'
    },
    google: {
      type: '2'
    },
    yahoo: {
      type: '2'
    },
    bing: {
      type: '2'
    },
    facebook: {
      type: '3'
    },
    youtube: {
      type: '3'
    },
    twitter: {
      type: '3'
    },
    't.co': {
      type: '3'
    },
    instagram: {
      type: '3'
    }
  };
  var dirName = '_direct_';
  var otherName = '_other_';

  // 和配置信息匹配，所识别的来源是否在配置信息范围内，如果没有这视为other网站
  function _find(opt) {
    var rst = false;
    var name = false;
    // 循环找出 - 是否 - 符合最短关键字的
    Object.keys(third_website).forEach(function (value) {
      var k = "".concat(value);
      var v = third_website[value];
      if (opt.host.indexOf(k) !== -1 && rst === false) {
        rst = v;
        name = k;
      }
    });
    rst = rst || third_website[otherName];
    name = name || otherName;
    return {
      value: rst,
      name: _nameFn(name)
    };
  }

  // 过滤掉隐式域名
  function _nameFn(name) {
    return name === 't.co' ? 'twitter' : name;
  }
  var urlParams = parseURL().params;
  var hasGet = (urlParams === null || urlParams === void 0 ? void 0 : urlParams._ss_source) || (urlParams === null || urlParams === void 0 ? void 0 : urlParams._referrer) || false;
  // 如果有GET参数，则强制显示为GET参数
  if (hasGet) {
    var find = _find({
      host: "_".concat(hasGet)
    });
    var _rst = find.value;
    var _name = find.name;
    var _type2 = _rst.type;
    return {
      type: _type2,
      name: _name
    };
  }
  // 是否有referrer信息
  var ref = document.referrer || '';
  var rst = [];
  var name = otherName;
  if (ref) {
    // 验证URL格式，可支持N级域名和N级后缀
    var match = ref.match(/(http|https):\/\/[\w\d-.]+/);
    if (match && match[0] && match[0].length > 0) {
      // url验证通过
      var host = "_".concat(match[0]);
      var _find2 = _find({
        host: host
      });
      rst = _find2.value;
      name = _find2.name;
    } else {
      // url不通过
      rst = third_website[dirName];
      name = dirName;
    }
  } else {
    // 如果没有referrer信息，则视为是直接访问
    rst = third_website[dirName];
    name = dirName;
  }
  var _type = rst.type;
  return {
    type: _type,
    name: _nameFn(name)
  };
};

// 随机字符串
export var getRangeChar = function getRangeChar(len) {
  var t;
  for (t = ''; t.length < len; t += Math.random().toString(36).substr(2));
  return t.substr(0, len);
};
export var getCurrentTs = function getCurrentTs() {
  return window.performance && typeof window.performance.now === 'function' ? Math.floor(performance.now()) : Date.now();
};

// 判断是否在iframe内
export var isInIframe = function isInIframe() {
  return self.frameElement && self.frameElement.tagName === 'IFRAME';
};
export function calcTimeLeft(duration) {
  var time = Math.floor(duration);
  var paddingZero = function paddingZero(num) {
    return !num ? '00' : num < 10 ? "0".concat(num) : "".concat(num);
  };
  var UNITS = ['hour', 'minute', 'second'];
  var values = {};
  [3600, 60, 1].forEach(function (seconds, i) {
    var value = Math.floor(time / seconds);
    values[UNITS[i]] = paddingZero(value);
    time -= value * seconds;
  });
  return values;
}

/**
 * 增加灰度分组函数
 *
 * @param {number} groups 灰度分组权重，会对全部权重求和，再生成随机数，看随机数所在区间，对应所在灰度组
 * @returns {number} 所在灰度组索引
 */
export function getGreyGroup() {
  for (var _len = arguments.length, groups = new Array(_len), _key = 0; _key < _len; _key++) {
    groups[_key] = arguments[_key];
  }
  var length = groups.length;
  var floor = 0;
  var ceil = 0;
  var sum = groups.reduce(function (prev, current) {
    return prev + current;
  }, 0);
  var randomNum = Math.random() * sum;
  var index = groups.findIndex(function (group, i) {
    ceil += group;
    if (randomNum >= floor && randomNum < ceil ||
    // randomNum 属于 [floor, ceil)
    i === length - 1 && randomNum === ceil // randomNum === 上限点
    ) {
      return true;
    } else {
      floor += group;
    }
  });
  return index;
}

/**
 * notes：同下理，是对空格的特殊处理。有需要联合使用
 */
export function serializeParam(value) {
  return encodeURIComponent(value).replace(/%20/g, '+');
}

/**
 * notes: 如果手动输入的值有空格，浏览器或form或其他自动encode的时候会变成+，所以在手动decode前，需要把 + 替换成空格。
 */
export function deserializeParam(value) {
  return decodeURIComponent(String(value).replace(/\+/g, ' '));
}

/**
 * 获取操作系统名称-除了安卓和IOS、wap，其他情况是web
 */
export function getNimoOSName() {
  if (ua.isInNimoStreamer) {
    return 'nimostreamer';
  }
  var name = ua.os.name.toLocaleLowerCase();
  var rst;
  if (ua.isInNimoApp) {
    rst = name;
  } else if (ua.isWap) {
    rst = 'wap';
  } else {
    rst = 'web';
  }
  return rst;
}

// 数组-找出最接近的数字值
export function arrNearNumber(array, search) {
  var _array = array.slice(0);
  _array.sort(function (a, b) {
    return Math.abs(a - search) - Math.abs(b - search);
  });
  return _array[0];
}
export var noop = function noop() {};
export var getEClientType = function getEClientType() {
  if (ua.isIOS) {
    return EClientType.ClientTypeIos;
  } else if (ua.isAdr) {
    return EClientType.ClientTypeAndroid;
  } else {
    return EClientType.ClientTypeWeb;
  }
};

/**
 * 判断是否是游戏品类，排除了秀场、户外、语音房、颜值、二次元、just chatting品类
 * @param {number|string} gameId 品类id
 * @returns boolean
 */
export var checkIsGame = function checkIsGame(gameId) {
  var _gameId = Number(gameId);
  return [185, 420, 208, 210, 211, 263].indexOf(_gameId) === -1;
};
export var getRoomWay = function getRoomWay(gameId) {
  switch (Number(gameId)) {
    case 185:
      return 'starshow';
    case 420:
      return 'voice';
    case 208:
    case 210:
    case 211:
    case 263:
      return 'others';
    default:
      return 'game';
  }
};
export var isFn = function isFn(obj) {
  return typeof obj === 'function';
};
export var isUndefined = function isUndefined(obj) {
  return typeof obj === 'undefined';
};
export var isString = function isString(obj) {
  return typeof obj === 'string';
};

/**
 * 判断是个性化域名还是roomId
 * @param {number|string} data 
 * @returns boolean
 * 个性化域名规则：
 * 5-25个字符
 * 只能纯字母或字母+数字的组合,字符不可完全重复(例如：aa、11)
 * 只支持小写字母（但因Google nimo mixi 都是大写的，故兼容下大写，在业务逻辑处再转小写）
 * 
 */
export var checkIsCustomize = function checkIsCustomize(data) {
  var isRoomId = /^\d+$/.test(data);
  return !isRoomId && /[a-zA-Z]+/.test(data);
};

/**
 * 获取操作系统信息
 * @returns
 */
export var getCPU = function getCPU() {
  var osMatch = navigator.platform.match(/(Win|Mac|Linux)/);
  var os = osMatch && osMatch[1] || '';
  var arch = navigator.userAgent.match(/x86_64|Win64|WOW64/) || navigator.cpuClass === 'x64' ? 'x64' : 'x86';
  return {
    os: os,
    arch: arch
  };
};

/**
 * 获取域名，自动区分环境
 * @returns
 */
export var getDomain = function getDomain() {
  var D = DOMAINS;
  if (ua.isWeb) {
    return D.maindomain;
  } else {
    return D.wapdomain;
  }
};

/**
 * 将数值较大的数字转换成后带单位K或M的形式
 * @param {number} originalNumber 欲进行转换的目标数字
 * @param {number} dotPrecision 小数点后保留几位小数
 * @returns {string} 完成转换后的数字
 * @example simplifyNumber(17498700) -> '17.50M'
 */
export var simplifyNumber = function simplifyNumber() {
  var originalNumber = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  var dotPrecision = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  if (originalNumber >= 0 && originalNumber <= 999) return originalNumber;
  if (originalNumber >= 1000 && originalNumber <= 999999) return "".concat((originalNumber / 1000).toFixed(dotPrecision), "K");
  if (originalNumber >= 1000000) return "".concat((originalNumber / 1000000).toFixed(dotPrecision), "M");
  return originalNumber;
};