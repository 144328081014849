import isStandalone from '@client/common/lib/install-pwa/isStandalone';
import { parseURL } from '@client/common/utils/fn';
function getFromPC() {
  var host = document.referrer && parseURL(document.referrer).host;
  if (host && (host === DOMAINS.maindomain || host === DOMAINS.dashboarddomain)) {
    return 'home';
  } else {
    return 'other';
  }
}
export function reportIndexPC(nimoReport) {
  if (location.pathname === '/') {
    nimoReport.report(nimoReport.formatReportObj('home_pv', '首页_页面浏览', {
      from_: isStandalone() ? 'pwa' : getFromPC(),
      group_: 'B'
    }));
  }
}
function getFromH5() {
  var host = document.referrer && parseURL(document.referrer).host;
  if (host && host === DOMAINS.wapdomain) {
    return 'home';
  } else {
    return 'other';
  }
}
export function reportIndexH5(nimoReport) {
  if (location.pathname === '/') {
    nimoReport.report(nimoReport.formatReportObj('h5_home_pv', 'h5_首页_页面浏览', {
      from_: isStandalone() ? 'pwa' : getFromH5()
    }));
  }
}