import { getUrlParams } from '@nimo-fed/util';
import { cookie } from '@client/common/utils/storage';
import ua from '@client/common/utils/ua';
var configByMode = {
  1: [[[10, 23], [10, 25], 45]],
  3: [[[10, 23], [10, 25], 40]]
};
function getToday() {
  if (!window.G_TODAY) {
    var nowUTC = new Date();
    var utc8Date = new Date(nowUTC.getTime() + 28800000);
    var month = utc8Date.getUTCMonth() + 1;
    var day = utc8Date.getUTCDate();
    window.G_TODAY = [month, day];
  }
  return window.G_TODAY;
}
function isLarger(a, b) {
  return a[0] > b[0] || a[0] === b[0] && a[1] >= b[1];
}
function getRate(current, mode) {
  var config = configByMode[mode];
  for (var index = 0; index < config.length; index++) {
    var from = config[index][0];
    var to = config[index][1];
    var rate = config[index][2];
    if (index === config.length - 1 && isLarger(current, to)) {
      return rate;
    }
    if (isLarger(current, from) && isLarger(to, current)) {
      return rate;
    }
  }
  return 0;
}
export function getEnableMultipleReport() {
  if (window.G_ENABLE_M_REPORT !== undefined) {
    return window.G_ENABLE_M_REPORT;
  }
  var mode = window.G_MULTIPLE_REPORT_MODE;
  if (mode === '0') {
    window.G_ENABLE_M_REPORT = false;
    return false;
  }
  var RATE_DEFAULT = 0;
  var CURRENT_VERSION = '-1';
  if (mode === '1' || mode === '3') {
    RATE_DEFAULT = getRate(getToday(), mode);
    CURRENT_VERSION = String(RATE_DEFAULT);
  }
  var cookieKey = "report-multi-".concat(mode);
  var currentSet = cookie.get(cookieKey);
  var set1 = currentSet && currentSet.split(',');
  var version = set1 && set1[0];
  var enable = set1 ? set1[1] : '0';
  var rate_input = getUrlParams('multiple-report-rate');
  var isForceOpen = mode === '2';
  var enableMultiple = isForceOpen || enable === '1';
  if (rate_input || !enableMultiple && version !== CURRENT_VERSION) {
    var rate = (rate_input ? Number(rate_input) : RATE_DEFAULT) / 100;
    enableMultiple = isForceOpen ? true : Math.random() < rate;
    if (!rate_input && mode !== '0' && mode !== '2') {
      cookie.set(cookieKey, [CURRENT_VERSION, enableMultiple ? '1' : '0'].join(','), 365);
    }
  }
  window.G_ENABLE_M_REPORT = enableMultiple;
  return enableMultiple;
}
export var getMultiplePro = function getMultiplePro() {
  if (window.G_REPORT_MULTI_PRO) {
    return window.G_REPORT_MULTI_PRO;
  }
  var isNimoApp = /nimo/.test(navigator.userAgent);
  var isWap = ua.isWap;
  var pros = [];
  pros.push(isWap ? DOMAINS.pasnimowap : DOMAINS.pasnimoweb);
  pros.push(isNimoApp ? DOMAINS.pas_nimo_inner : DOMAINS.pas_nimo_outer);
  var x = pros.join(',');
  window.G_REPORT_MULTI_PRO = x;
  return x;
};