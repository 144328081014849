module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<style>\n.hot-game-skeleton-wrapper > span {\n  flex-shrink: 0;\n  flex-basis: 76px;\n  max-width: 76px;\n}\n</style>\n\n<div class="n-fx-bc" style="margin-top: 15px;padding: 0 14px; margin-bottom: 8px">\n  <div class="n-sk n-sk-t18" style="width: 200px;height: 18px;"></div>\n  <div class="n-sk n-sk-t18" style="width: 50px;height: 18px;"></div>\n</div>\n<div class="hot-game-skeleton-wrapper n-fx-sc" style="padding: 0 14px; margin-bottom: 14px; overflow: scroll;">\n  ';
 for (var i = 0; i < 8; i++ ) { ;
__p += '\n    <span class="n-fx-cc n-fx-col" style="margin-bottom: 12px;">\n      <div class="n-sk n-sk-ava"></div>\n      <div class="n-sk n-sk-t12" style="margin-top: 6px;height: 14px; width: 100%;max-width: 65px;min-width: 20px"></div>\n    </span>\n  ';
 } ;
__p += '\n</div>\n';

}
return __p
}