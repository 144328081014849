import { session } from '@client/common/utils/storage';

/**
 * @param {{ from: string }} data
 */
export var setRoomEnterInfo = function setRoomEnterInfo(data) {
  try {
    session.set('roomenterinfo', JSON.stringify(data));
    session.set('roomenterTraceData', JSON.stringify({
      traceData: data.traceData
    }));
  } catch (err) {
    // ignore err
  }
};