import sleep from './sleep';

/**
 * @template T
 * @param {(...args: any[]) => Promise<T>} miss
 * @param {number} [delay]
 * @param {number} [max]
 */
var retry = function retry(miss) {
  var delay = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1000;
  var max = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 5;
  var t = 0;

  /**
   * @type {typeof miss} 
   */
  var _exec = function exec() {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    t += 1;
    return miss.apply(void 0, args).catch(function (e) {
      if (t < max) {
        return sleep(delay).then(function () {
          return _exec.apply(void 0, args);
        });
      }
      throw e;
    });
  };
  return _exec;
};
export default retry;