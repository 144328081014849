import { getMid } from '@client/common/utils/fn';
import getUrlParams from '@client/common/utils/getUrlParams';
import ABTEST from './lib/abtest';
import getAbConfig, { getSphdExp, checkInSphdWhiteList } from './config';
var mid = getMid();
var oexp;
function getOexp(isWap) {
  if (oexp) {
    return oexp;
  } else {
    var abConfig = getAbConfig(isWap);
    var abtest = new ABTEST(abConfig, mid);
    return abtest.config();
  }
}
export function isSPHD(isWap) {
  var _sphdFromUrl = getUrlParams('_sphd'); // 方便测试，通过 url 设置 sphd
  if (_sphdFromUrl) {
    return _sphdFromUrl === '1';
  }
  var _oexp = getOexp(isWap);
  var _sphdExp = getSphdExp(isWap);
  return Array.isArray(_oexp) ? _oexp.indexOf(_sphdExp) > -1 : _oexp === _sphdExp;
}
export { checkInSphdWhiteList };
export default getOexp;