export var isPCDomain = location.hostname === DOMAINS.maindomain || location.hostname === DOMAINS.dashboarddomain;
var _p = location.protocol;
if (_p !== 'https:' && _p !== 'http:') {
  _p = 'https:';
}
export var protocol = _p;
export var twoEndHost = isPCDomain ? DOMAINS.maindomain : DOMAINS.wapdomain;

// 根据当前域名找到对应的主站域名
export var twoEndOrigin = "".concat(protocol, "//").concat(twoEndHost);

// eslint-disable-next-line prefer-destructuring
export var wapdomain = DOMAINS.wapdomain;

// eslint-disable-next-line prefer-destructuring
export var maindomain = DOMAINS.maindomain;

// eslint-disable-next-line prefer-destructuring
export var dashboarddomain = DOMAINS.dashboarddomain;
export var origins = ["".concat(protocol, "//").concat(wapdomain), "".concat(protocol, "//").concat(maindomain), "".concat(protocol, "//").concat(dashboarddomain)];