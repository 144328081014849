module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="nimo-bg-base"></div>\n<div id="container" class="container-width-header nimo-res ignore">\n  ' +
((__t = ( require('ejs-loader!@mobile/src/modules/wap_react/components/UnControlled/Blocks/Header/skeleton.ejs')() )) == null ? '' : __t) +
'\n  ' +
((__t = ( require('ejs-loader!./bannerSkeleton.ejs')() )) == null ? '' : __t) +
'\n  ' +
((__t = ( require('ejs-loader!./hotGameSkeleton.ejs')() )) == null ? '' : __t) +
'\n  ' +
((__t = ( require('ejs-loader!./recommondListSkeleton.ejs')() )) == null ? '' : __t) +
'\n</div>\n';

}
return __p
}