import _extends from "@babel/runtime/helpers/extends";
/**
 * 跨平台方法放在这里
 * keys:
 * - openBrowser
 */
var handlers = {};

/** 在页面入口注册方法 */
export var assignHandlers = function assignHandlers(functionMap) {
  _extends(handlers, functionMap);
};
export { handlers };