export default {
  get: function get(name) {
    // 获取
    var arr = document.cookie.match(new RegExp('(^| )' + name + '=([^;]*)(;|$)'));
    if (arr != null) {
      return decodeURI(arr[2]);
    }
    return null;
  },
  set: function set(name, value, days) {
    // 设置
    var Days = days || 30; // 保存天数
    var exp = new Date(); // new Date("December 31, 9998");
    exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
    var expire = 'expires=' + exp.toGMTString();
    var cookieValue = name + '=' + encodeURI(value) + ';domain=.nimo.tv;path=/;' + expire;
    document.cookie = cookieValue;
  },
  del: function del(name) {
    // 删除
    var exp = new Date();
    exp.setTime(exp.getTime() - 1);
    var cval = this.get(name);
    if (cval != null) {
      document.cookie = name + '=' + cval + ';domain=.nimo.tv;path=/;expires=' + exp.toGMTString();
    }
  }
};