import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { room } from './reducer';
var isTest = DOMAINS.isDebug === 'true';
var enhancers = function enhancers(opt) {
  var _enhancers = applyMiddleware(thunk);
  var __REDUX__ = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || false;
  return isTest && __REDUX__ ? __REDUX__({
    name: opt === null || opt === void 0 ? void 0 : opt.name
  })(_enhancers) : _enhancers;
};
export var roomStore = createStore(room, enhancers({
  name: 'Wap直播间 (roomStore)'
}));