export var sphdExp = '3919.5623';
export default [[{
  params: {
    groupId: '3091',
    g_trafficAlloc: ['0~9999'],
    salt: 'af98ae98',
    v_trafficAlloc: ['0~4999']
  },
  oexp: '3919.5622'
}, {
  params: {
    groupId: '3091',
    g_trafficAlloc: ['0~9999'],
    salt: 'af98ae98',
    v_trafficAlloc: ['5000~9999']
  },
  oexp: sphdExp
}]];