import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["type"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { SET_MEETING_INFO, SET_CONTENT_COUNTRY_LIST, SET_CONTENT_COUNTRY, SET_ROYAL_LEVEL_CONFIG, SET_POPOUT_STATE, SET_SELF_ROOM_LINK, UPDATE_LANGPKG } from './actions';
var initialState = {
  cCountry: '',
  popout: {},
  langPkg: {}
};

// 通用合并state的action type
var commonMergeTypes = [SET_MEETING_INFO, SET_CONTENT_COUNTRY_LIST, SET_CONTENT_COUNTRY, SET_ROYAL_LEVEL_CONFIG, SET_SELF_ROOM_LINK];
function commonReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var type = action.type,
    payload = _objectWithoutProperties(action, _excluded);
  if (commonMergeTypes.indexOf(type) > -1) {
    return _objectSpread(_objectSpread({}, state), payload);
  }
  switch (type) {
    case SET_POPOUT_STATE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          popout: _objectSpread(_objectSpread({}, state.popout), {}, _defineProperty({}, payload.key, payload.value))
        });
      }
    case UPDATE_LANGPKG:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          langPkg: _objectSpread(_objectSpread({}, state.langPkg), {}, _defineProperty({}, payload.key, payload.value))
        });
      }
    default:
      return state;
  }
}
export default commonReducer;