import nimoReport from './reportWithoutInit';
import { parseURL } from '@client/common/utils/fn';
import ua from '@client/common/utils/ua';

/**
 * 端外点击分享链接上报
 */
var reportFromCommonShare = function reportFromCommonShare() {
  var _parseURL;
  var urlParams = ((_parseURL = parseURL()) === null || _parseURL === void 0 ? void 0 : _parseURL.params) || {};
  var c = urlParams.c,
    af_sub1 = urlParams.af_sub1,
    af_sub2 = urlParams.af_sub2,
    af_sub3 = urlParams.af_sub3,
    af_sub4 = urlParams.af_sub4,
    af_sub5 = urlParams.af_sub5;
  if (c === 'common_share') {
    var data = {
      from: af_sub1,
      share_content: +af_sub2,
      share_id: +af_sub3,
      share_platform: af_sub4
    };
    if (ua.isWap) {
      data.wap_mid = af_sub5 || 'null';
    }
    nimoReport.formatAndSend('sys/show/heartbeat_from_commonshare', '系统曝光来自分享的访问', data);
  }
};

// 上报 startup、心跳
nimoReport.init();
// 在所有页面都要符合条件上报
reportFromCommonShare();
export default nimoReport;