module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 for (var j = 0; j < 2; j++ ) { ;
__p += '\n  <div class="n-fx-bc" style="margin-top: 14px;padding: 0 14px; margin-bottom: 8px">\n    <div class="n-sk n-sk-t18" style="width: 200px;height: 18px;"></div>\n    <div class="n-sk n-sk-t18" style="width: 50px;height: 18px;"></div>\n  </div>\n  <div class="n-fx-sn n-fx-wrap n-fx-grid-150" style="padding: 0 14px;margin-bottom: 14px;">\n    ';
 for (var i = 0; i < 8; i++ ) { ;
__p += '\n      <div class="n-fx-cc">\n          ' +
((__t = ( require('ejs-loader!@mobile/src/modules/wap_react/components/UnControlled/Blocks/LiveListItem/skeleton.ejs')() )) == null ? '' : __t) +
'\n      </div>\n    ';
 } ;
__p += '\n    ';
 for (var i = 0; i < 8; i++ ) { ;
__p += '\n      <div>\n      </div>\n    ';
 } ;
__p += '\n  </div>\n  ';
 if(j === 0) { ;
__p += '\n    ' +
((__t = ( require('ejs-loader!./activitySkeleton.ejs')() )) == null ? '' : __t) +
'\n    <div>\n      <div class="n-as-mrg-md n-fx-sc">\n        <div class="n-sk n-sk-btn n-fx1" style="border-radius: 16px;"></div>\n        <div class="n-fx-ac">\n          <div class="n-sk n-sk-ava-sm-o n-as-mrgh-xxs"></div>\n          <div class="n-sk n-sk-ava-sm-o n-as-mrgh-xxs"></div>\n          <div class="n-sk n-sk-ava-sm-o n-as-mrgh-xxs"></div>\n        </div>\n      </div>\n    </div>\n  ';
 } ;
__p += '\n';
 } ;
__p += '\n';

}
return __p
}